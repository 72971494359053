import { jsx as _jsx } from "react/jsx-runtime";
import { useTypedMutation } from 'agency/generated/zeus/apollo';
import React, { useContext, useEffect, useState } from 'react';
import { useCurrentUser } from './CurrentUserContext';
export const ReviewCrawlingContext = React.createContext({
    showingInfoMessage: false,
    hideInfoMessage: () => null,
});
export const ReviewCrawlingProvider = ({ children }) => {
    const { currentUser } = useCurrentUser();
    const [infoMessageOpen, setInfoMessageOpen] = useState(false);
    const [requestCustomerReviewsCrawl] = useTypedMutation({ requestCustomerReviewsCrawl: true }, {
        apolloOptions: {
            onCompleted: ({ requestCustomerReviewsCrawl }) => {
                setInfoMessageOpen(requestCustomerReviewsCrawl);
            },
        },
    });
    useEffect(() => {
        if (currentUser) {
            requestCustomerReviewsCrawl();
        }
    }, [currentUser, requestCustomerReviewsCrawl]);
    return (_jsx(ReviewCrawlingContext.Provider, Object.assign({ value: {
            showingInfoMessage: infoMessageOpen,
            hideInfoMessage: () => setInfoMessageOpen(false),
        } }, { children: children })));
};
export function useReviewCrawling() {
    return useContext(ReviewCrawlingContext);
}
