import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorBoundary } from '@appsignal/react';
import { StrictMode, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { APP_TITLE } from './constants/displays';
import { useAuthenticatedContext } from './context/AuthenticatedContext';
import { useCurrentUser } from './context/CurrentUserContext';
import { FilterProvider } from './context/FilterContext';
import { useTypedQuery } from './generated/zeus/apollo';
import { appsignal } from './lib/appsignal';
import { getCelloBootOptions } from './lib/cello';
import Loading from './pages/Loading';
import Referral from './pages/Referral';
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const AuthenticatedRoutes = [
    _jsx(Route, { path: "/", render: () => _jsx(Layout, {}) }, "app"),
];
const UnauthenticatedRoutes = [
    _jsx(Route, { component: Login, path: "/login" }, "lg"),
    _jsx(Route, { component: Referral, path: "/refer" }, "loading"),
];
const REACT_APP_CELLO_API_ENDPOINT = process.env.REACT_APP_CELLO_API_ENDPOINT || 'https://api.sandbox.cello.so';
function App() {
    const { authenticated, setAuthenticated } = useAuthenticatedContext();
    const { currentUser, setCurrentUser } = useCurrentUser();
    const isAliasDomain = process.env.NODE_ENV === 'production' &&
        window.location.hostname.includes('admin');
    const { loading } = useTypedQuery({
        me: {
            id: true,
            email: true,
            owner: {
                __typename: true,
                '...on Customer': {
                    id: true,
                    celloToken: true,
                    firstName: true,
                    lastName: true,
                },
                '...on AdminStaff': {
                    id: true,
                },
            },
        },
    }, {
        apolloOptions: {
            skip: authenticated === false || isAliasDomain,
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                const { owner } = data.me;
                if (owner.__typename === 'Customer') {
                    setAuthenticated(true);
                    setCurrentUser({ me: Object.assign(Object.assign({}, data.me), { owner }) });
                }
                else {
                    setAuthenticated(false);
                }
            },
            onError: () => setAuthenticated(false),
        },
    });
    useEffect(() => {
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.me.owner.celloToken))
            return;
        window.cello = window.cello || { cmd: [] };
        window.cello.cmd.push(function (cello) {
            cello.boot(getCelloBootOptions({
                token: currentUser.me.owner.celloToken,
                email: currentUser.me.email,
                firstName: currentUser.me.owner.firstName,
                lastName: currentUser.me.owner.lastName,
            }));
            cello.show();
        });
    }, [currentUser]);
    useEffect(() => {
        if (!authenticated) {
            const cello = window.cello;
            if (cello) {
                cello.cmd.push(function (cello) {
                    cello.shutdown();
                });
            }
        }
    }, [authenticated]);
    if (isAliasDomain) {
        window.location.replace(window.location.origin + '/app/');
        return null;
    }
    return (_jsx(StrictMode, { children: _jsx(ErrorBoundary, Object.assign({ instance: appsignal }, { children: _jsxs(FilterProvider, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: APP_TITLE }), _jsx("script", { async: true, src: `${REACT_APP_CELLO_API_ENDPOINT}/app/v2/latest/cello.js`, type: "module" })] }), _jsxs(Switch, { children: [loading && _jsx(Route, { component: Loading, path: "/" }, "loading"), authenticated === true && currentUser && AuthenticatedRoutes, authenticated === false && UnauthenticatedRoutes, authenticated === false && _jsx(Redirect, { to: "/login" })] })] }) })) }));
}
export default App;
