import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
export const ShoppingCartContext = React.createContext({
    shoppingCart: { reviewsDeletions: [] },
    setShoppingCart: () => null,
    resetShoppingCart: () => null,
    toggleReviewFromCart: () => null,
    removeReviewFromCart: () => null,
});
export const shoppingCartInitialState = JSON.stringify({
    reviewsDeletions: [],
});
export const SHOPPING_CART_STORAGE_KEY = process.env.NODE_ENV === 'production'
    ? 'shoppingCart'
    : `${process.env.NODE_ENV}:shoppingCart`;
export const ShoppingCartProvider = ({ children }) => {
    const [localStorage, setLocalStorage] = useLocalStorage(SHOPPING_CART_STORAGE_KEY);
    const [shoppingCart, setShoppingCart] = useState(JSON.parse(localStorage || shoppingCartInitialState));
    useEffect(() => {
        setLocalStorage(JSON.stringify(shoppingCart));
    }, [setLocalStorage, shoppingCart, shoppingCart.reviewsDeletions]);
    const resetShoppingCart = useCallback(() => setShoppingCart({ reviewsDeletions: [] }), []);
    const removeReviewFromCart = useCallback((reviewId) => setShoppingCart(({ reviewsDeletions }) => ({
        reviewsDeletions: reviewsDeletions.filter((currReview) => currReview.id !== reviewId),
    })), []);
    const toggleReviewFromCart = useCallback((review) => {
        const { id, title, onlineProfile: { id: onlineProfileId, link }, url, reviewerName, description, normalizedScore, date, } = review;
        const reviewToDelete = shoppingCart.reviewsDeletions.find(({ id: currId }) => currId === id);
        setShoppingCart(({ reviewsDeletions }) => {
            if (reviewToDelete) {
                return {
                    reviewsDeletions: reviewsDeletions.filter(({ id: currId }) => currId !== id),
                };
            }
            return {
                reviewsDeletions: [
                    ...reviewsDeletions,
                    {
                        id,
                        title,
                        onlineProfileId,
                        link,
                        url,
                        reviewerName,
                        description,
                        normalizedScore,
                        date,
                    },
                ],
            };
        });
    }, [shoppingCart.reviewsDeletions]);
    return (_jsx(ShoppingCartContext.Provider, Object.assign({ value: {
            setShoppingCart,
            shoppingCart,
            resetShoppingCart,
            toggleReviewFromCart,
            removeReviewFromCart,
        } }, { children: children })));
};
export function useShoppingCart() {
    return useContext(ShoppingCartContext);
}
