export function getCelloBootOptions(data) {
    return {
        productId: process.env.REACT_APP_CELLO_PRODUCT_ID || 'dev-onnoplus.de',
        token: data.token,
        language: 'de',
        productUserCountryCode: 'DE',
        productUserDetails: {
            firstName: data.firstName,
            lastName: data.lastName,
            fullName: data.firstName + ' ' + data.lastName,
            email: data.email,
        },
        hideDefaultLauncher: true,
    };
}
