import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './assets/css/tailwind.output.css';
import { ApolloProvider } from '@apollo/client';
import countries from 'i18n-iso-countries';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { AuthenticatedProvider } from './context/AuthenticatedContext';
import { CurrentUserProvider } from './context/CurrentUserContext';
import { ReviewCrawlingProvider } from './context/ReviewCrawlingContext';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { ThemeProvider } from './context/ThemeContext';
import i18n from './i18n';
import { zenstarClient } from './lib/apollo';
import Loading from './pages/Loading';
/* eslint-disable @typescript-eslint/no-var-requires */
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
ReactDOM.render(_jsx(AuthenticatedProvider, { children: _jsx(CurrentUserProvider, { children: _jsx(ThemeProvider, { children: _jsx(ApolloProvider, Object.assign({ client: zenstarClient }, { children: _jsxs(Suspense, Object.assign({ fallback: _jsx(Loading, {}) }, { children: [_jsx(ToastContainer, { newestOnTop: true }), _jsx(I18nextProvider, Object.assign({ i18n: i18n }, { children: _jsx(BrowserRouter, { children: _jsx(ShoppingCartProvider, { children: _jsx(ReviewCrawlingProvider, { children: _jsx(App, {}) }) }) }) }))] })) })) }) }) }), document.getElementById('root'));
