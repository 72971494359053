/* eslint-disable */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Zeus } from './index';
export function useTypedMutation(mutation, options) {
    return useMutation(gql(Zeus("mutation", mutation, {
        operationOptions: options === null || options === void 0 ? void 0 : options.operationOptions,
        scalars: options === null || options === void 0 ? void 0 : options.scalars
    })), options === null || options === void 0 ? void 0 : options.apolloOptions);
}
export function useTypedQuery(query, options) {
    return useQuery(gql(Zeus("query", query, {
        operationOptions: options === null || options === void 0 ? void 0 : options.operationOptions,
        scalars: options === null || options === void 0 ? void 0 : options.scalars
    })), options === null || options === void 0 ? void 0 : options.apolloOptions);
}
export function useTypedLazyQuery(LazyQuery, options) {
    return useLazyQuery(gql(Zeus("query", LazyQuery, {
        operationOptions: options === null || options === void 0 ? void 0 : options.operationOptions,
        scalars: options === null || options === void 0 ? void 0 : options.scalars
    })), options === null || options === void 0 ? void 0 : options.apolloOptions);
}
/* import-sort-ignore */
