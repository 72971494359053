import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
export const CurrentUserContext = React.createContext({
    currentUser: undefined,
    setCurrentUser: () => null,
});
export const CurrentUserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    return (_jsx(CurrentUserContext.Provider, Object.assign({ value: { setCurrentUser, currentUser } }, { children: children })));
};
export function useCurrentUser() {
    return useContext(CurrentUserContext);
}
