import { jsx as _jsx } from "react/jsx-runtime";
import LoadingIndicator from 'agency/components/widgets/LoadingIndicator';
import { useTypedQuery } from 'agency/generated/zeus/apollo';
import { getCelloBootOptions } from 'agency/lib/cello';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
function Referral() {
    const code = new URLSearchParams(window.location.search).get('code');
    const { loading, data, error } = useTypedQuery({
        celloTokenGuest: [
            { code: code || '' },
            {
                celloToken: true,
                firstName: true,
                lastName: true,
                email: true,
            },
        ],
    });
    useEffect(() => {
        if (!(data === null || data === void 0 ? void 0 : data.celloTokenGuest))
            return;
        window.cello = window.cello || { cmd: [] };
        window.cello.cmd.push(function (cello) {
            cello.boot(Object.assign(Object.assign({}, getCelloBootOptions({
                token: data.celloTokenGuest.celloToken,
                email: data.celloTokenGuest.email,
                firstName: data.celloTokenGuest.firstName,
                lastName: data.celloTokenGuest.lastName,
            })), { onClose: () => {
                    cello.open();
                } }));
            cello.open();
        });
    }, [data]);
    if (error) {
        return _jsx(Redirect, { to: "/login" });
    }
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-screen h-screen bg-[#2D2D4D] pointer-events-none" }, { children: loading && _jsx(LoadingIndicator, {}) })));
}
export default Referral;
