export const APP_TITLE = 'Onno Plus Kunden Portal';
export const LOADING_INDICATOR_COLOR = '#111827';
export const BORDER_COLOR = '#E5E5E5';
export const MOBILE_BREAKPOINT_WIDTH = 1024;
export const HAS_MORE_THRESHOLD = 120;
export const MOBILE_CHART_DATA_SIZE = 6;
export const MOMENT_DATE_FORMAT_ALT = 'DD.MM.YYYY';
export const DATE_PICKER_FORMAT = 'DD.MM.YYYY';
export const MIN_REVIEW_SCORE = 1;
export const MAX_REVIEW_SCORE = 5;
export const GOOD_REVIEW_SCORE = 4;
export const STARS = [5, 4, 3, 2, 1];
export const MAX_STARS = 5;
export var TimePeriodOption;
(function (TimePeriodOption) {
    TimePeriodOption["last30Days"] = "last30Days";
    TimePeriodOption["last60Days"] = "last60Days";
    TimePeriodOption["last3Months"] = "last3Months";
    TimePeriodOption["last6Months"] = "last6Months";
    TimePeriodOption["last12Months"] = "last12Months";
    TimePeriodOption["allTime"] = "allTime";
})(TimePeriodOption || (TimePeriodOption = {}));
export const getTimePeriodOptionLabels = (t) => {
    return {
        last30Days: t('last30Days'),
        last60Days: t('last60Days'),
        last3Months: t('last3Months'),
        last6Months: t('last6Months'),
        last12Months: t('last12Months'),
        allTime: t('allTime'),
    };
};
export const FILTER_STORAGE_KEY = 'filterStars';
