export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["locations"] = "locations";
    FilterTypes["platforms"] = "platforms";
    FilterTypes["keywords"] = "keywords";
    FilterTypes["timePeriod"] = "timePeriod";
    FilterTypes["comments"] = "comments";
    FilterTypes["ratingSlider"] = "ratingSlider";
})(FilterTypes || (FilterTypes = {}));
export var PagionationDirection;
(function (PagionationDirection) {
    PagionationDirection["prev"] = "prev";
    PagionationDirection["next"] = "next";
})(PagionationDirection || (PagionationDirection = {}));
