import { jsx as _jsx } from "react/jsx-runtime";
import { FilterTypes } from 'agency/constants/enums';
import { SortOrder } from 'agency/generated/zeus';
import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { useHistory } from 'react-router-dom';
export const FilterAccess = {
    dashboard: [
        FilterTypes.locations,
        FilterTypes.platforms,
        FilterTypes.timePeriod,
    ],
    reviews: [
        FilterTypes.locations,
        FilterTypes.platforms,
        FilterTypes.timePeriod,
        FilterTypes.ratingSlider,
        FilterTypes.keywords,
    ],
};
export const FilterContext = React.createContext({
    values: {
        sortByDate: SortOrder.desc,
        sortByScore: undefined,
        searchBy: '',
        resetEnabled: false,
        filterByPlatformKey: undefined,
        filterByLocationId: undefined,
        fromDate: new Date(0),
        untilDate: new Date(),
        isCustomDurationFilter: false,
        stars: [],
        currentView: undefined,
        timePeriod: undefined,
        selectedKeyword: undefined,
        filterByComment: undefined,
    },
    setters: {
        setSortByDate: () => null,
        setSortByScore: () => null,
        setSearchBy: () => null,
        setResetEnabled: () => null,
        setFilterByPlatformKey: () => null,
        setFilterByLocationId: () => null,
        setIsCustomDurationFilter: () => null,
        setFromDate: () => null,
        setUntilDate: () => null,
        setStars: () => null,
        setTimePeriod: () => null,
        setSelectedKeyword: () => null,
        setFilterByComment: () => null,
    },
    onResetClick: () => null,
});
export const FilterProvider = ({ children }) => {
    const history = useHistory();
    const [sortByDate, setSortByDate] = useState(SortOrder.desc);
    const [sortByScore, setSortByScore] = useState();
    const [searchBy, setSearchBy] = useState('');
    const [resetEnabled, setResetEnabled] = useState(false);
    const [filterByLocationId, setFilterByLocationId] = useState();
    const [filterByPlatformKey, setFilterByPlatformKey] = useState();
    const [fromDate, setFromDate] = useState(null);
    const [untilDate, setUntilDate] = useState(null);
    const [stars, setStars] = useState([]);
    const [timePeriod, setTimePeriod] = useState();
    const [isCustomDurationFilter, setIsCustomDurationFilter] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState();
    const [filterByComment, setFilterByComment] = useState();
    const handleResetClick = useCallback(() => {
        setSearchBy('');
        setResetEnabled(true);
        setFromDate(null);
        setUntilDate(null);
        setStars([]);
        setFilterByPlatformKey(undefined);
        setFilterByLocationId(undefined);
        setTimePeriod(undefined);
        setIsCustomDurationFilter(false);
        setSelectedKeyword(undefined);
        setFilterByComment(undefined);
    }, [
        setFilterByLocationId,
        setFilterByPlatformKey,
        setFromDate,
        setSearchBy,
        setResetEnabled,
        setStars,
        setUntilDate,
        setTimePeriod,
        setIsCustomDurationFilter,
        setSelectedKeyword,
        setFilterByComment,
    ]);
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            const pathname = location.pathname;
            const match = pathname.endsWith('/filter');
            if (action !== 'POP' && !match) {
                handleResetClick();
            }
        });
        return () => unlisten();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResetClick, location]);
    const currentView = useMemo(() => {
        const { pathname } = history.location;
        if (pathname.includes('reviews')) {
            return 'reviews';
        }
    }, [history.location]);
    return (_jsx(FilterContext.Provider, Object.assign({ value: {
            values: {
                sortByDate,
                sortByScore,
                searchBy,
                resetEnabled,
                filterByPlatformKey,
                filterByLocationId,
                fromDate,
                untilDate,
                isCustomDurationFilter,
                stars,
                currentView,
                timePeriod,
                selectedKeyword,
                filterByComment,
            },
            setters: {
                setSortByDate,
                setSortByScore,
                setSearchBy,
                setResetEnabled,
                setFilterByPlatformKey,
                setFilterByLocationId,
                setFromDate,
                setUntilDate,
                setIsCustomDurationFilter,
                setStars,
                setTimePeriod,
                setSelectedKeyword,
                setFilterByComment,
            },
            onResetClick: handleResetClick,
        } }, { children: children })));
};
export function useFilter() {
    return useContext(FilterContext);
}
