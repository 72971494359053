/* eslint-disable */
export const AllTypesProps = {
    AdminBillingAddressCreateInput: {
        gender: "Gender",
        location: "IdInput"
    },
    AdminBillingAddressUpdateInput: {
        gender: "Gender"
    },
    AdminBillingAddressWhereInput: {
        companyName: "StringFilter",
        country: "StringFilter",
        firstName: "StringFilter",
        lastName: "StringFilter"
    },
    AdminCustomerCreateInput: {
        gender: "Gender",
        locations: "AdminLocationCreateWithoutCustomerInput",
        manager: "IdInput"
    },
    AdminCustomerLogEventCreateInput: {
        callOutcome: "CallOutcome",
        customer: "IdInput",
        date: "DateTime"
    },
    AdminCustomerOverviewSortInput: {
        billedAmount: "SortOrder",
        customerLocations: "SortOrder",
        lastOrderAt: "SortOrder",
        orderCount: "SortOrder",
        potentialRemovalsCount: "SortOrder",
        totalSpend: "SortOrder"
    },
    AdminCustomerOverviewWhereInput: {
        potentialRemovalsCount: "IntFilter"
    },
    AdminCustomerSortInput: {
        createdAt: "SortOrder",
        id: "SortOrder",
        lastSuppliedAt: "SortOrder",
        overview: "AdminCustomerOverviewSortInput",
        updatedAt: "SortOrder"
    },
    AdminCustomerUpdateInput: {
        gender: "Gender",
        manager: "IdInput",
        paymentMethod: "PaymentMethod"
    },
    AdminCustomerWhereInput: {
        AND: "AdminCustomerWhereInput",
        OR: "AdminCustomerWhereInput",
        billingAddress: "AdminBillingAddressWhereInput",
        blacklistReason: "StringFilter",
        currentSupplier: "IdInput",
        customerId: "IntFilter",
        customerPortalPassword: "StringFilter",
        email: "StringFilter",
        firstName: "StringFilter",
        id: "IntFilter",
        lastName: "StringFilter",
        locations: "AdminLocationWhereInputListRelationFilter",
        manager: "IdInput",
        orders: "AdminOrderWhereInputListRelationFilter",
        overview: "AdminCustomerOverviewWhereInput",
        paymentMethod: "PaymentMethod",
        phoneNumbers: "StringListFilter",
        userId: "IntFilter"
    },
    AdminEmailTemplateWhereInput: {},
    AdminEventCustomerNoteCreateData: {
        customer: "IdInput"
    },
    AdminEventLeadNoteCreateData: {
        lead: "IdInput"
    },
    AdminEventMailWhereInput: {
        trackingStatus: "StringFilter"
    },
    AdminEventNoteUpdateData: {},
    AdminEventOrderNoteCreateData: {
        order: "IdInput"
    },
    AdminInstallmentPaymentsCreateInput: {
        order: "IdInput"
    },
    AdminLawyerCreateData: {},
    AdminLawyerUpdateData: {},
    AdminLawyerWhereInput: {
        platformId: "IntFilter"
    },
    AdminLeadAllocationRuleRegionUpdateInput: {},
    AdminLeadCategoryInput: {},
    AdminLeadLogEventCreateInput: {
        callOutcome: "CallOutcome",
        date: "DateTime",
        lead: "IdInput"
    },
    AdminLeadLossInput: {
        status: "AdminLeadStatus"
    },
    AdminLeadOfflineCampaignCreateInput: {},
    AdminLeadOfflineCampaignWhereInput: {
        id: "IntFilter"
    },
    AdminLeadSortInput: {
        assignedAt: "SortOrder",
        createdAt: "SortOrder",
        updatedAt: "SortOrder"
    },
    AdminLeadStatus: "enum",
    AdminLeadStatusFilter: {
        equals: "AdminLeadStatus",
        in: "AdminLeadStatus",
        notIn: "AdminLeadStatus"
    },
    AdminLeadUpdateInput: {
        gender: "Gender",
        status: "AdminLeadStatus"
    },
    AdminLeadWhereInput: {
        AND: "AdminLeadWhereInput",
        OR: "AdminLeadWhereInput",
        companyName: "StringFilter",
        email: "StringFilter",
        firstName: "StringFilter",
        googleScore: "FloatFilter",
        lastName: "StringFilter",
        leadOfflineCampaign: "AdminLeadOfflineCampaignWhereInput",
        managerId: "IntFilter",
        negativeReviewsCount: "IntFilter",
        parentCategoryName: "StringFilter",
        phone: "StringFilter",
        placeId: "StringFilter",
        status: "AdminLeadStatusFilter",
        zipCode: "StringFilter"
    },
    AdminLineItemCreateInput: {
        product: "IdInput",
        removalSubmission: "AdminRemovalSubmissionCreateInput"
    },
    AdminLineItemWhereInput: {
        productId: "IntFilter",
        removalSubmission: "AdminRemovalSubmissionWhereInput"
    },
    AdminLineItemWhereInputListRelationFilter: {
        every: "AdminLineItemWhereInput",
        none: "AdminLineItemWhereInput",
        some: "AdminLineItemWhereInput"
    },
    AdminLocationCreateInput: {
        customer: "IdInput"
    },
    AdminLocationCreateWithoutCustomerInput: {},
    AdminLocationUpdateInput: {},
    AdminLocationWhereInput: {
        title: "StringFilter"
    },
    AdminLocationWhereInputListRelationFilter: {
        every: "AdminLocationWhereInput",
        none: "AdminLocationWhereInput",
        some: "AdminLocationWhereInput"
    },
    AdminLogEventUpdateInput: {
        callOutcome: "CallOutcome",
        date: "DateTime"
    },
    AdminOfferCreateInput: {
        customer: "IdInput",
        expiresAt: "DateTime",
        gender: "Gender",
        lead: "IdInput",
        lineItems: "AdminOfferLineItemCreateInput"
    },
    AdminOfferLegacy: {
        platform: "IdInput"
    },
    AdminOfferLegacyInput: {
        gender: "Gender",
        locations: "AdminOfferLegacyLocation"
    },
    AdminOfferLegacyLocation: {
        offers: "AdminOfferLegacy",
        ratingPlatform: "IdInput"
    },
    AdminOfferLineItemCreateInput: {},
    AdminOfferLineItemUpdateInput: {},
    AdminOfferUpdateInput: {
        expiresAt: "DateTime",
        gender: "Gender",
        lineItems: "AdminOfferLineItemUpdateInput"
    },
    AdminOfferWebsiteCreateInput: {
        lineItem: "AdminOfferLineItemCreateInput"
    },
    AdminOfferWhereInput: {
        AND: "AdminOfferWhereInput",
        OR: "AdminOfferWhereInput",
        author: "IdInput",
        companyName: "StringFilter",
        slug: "StringFilter",
        status: "OfferStatus"
    },
    AdminOnlineProfileCreateInput: {
        location: "IdInput",
        platform: "IdInput"
    },
    AdminOnlineProfileUpdateInput: {},
    AdminOnlineProfileWhereInput: {},
    AdminOrderBalanceSortInput: {
        billableAmount: "SortOrder",
        billableBalance: "SortOrder"
    },
    AdminOrderBalanceWhereInput: {
        billableBalance: "IntFilter"
    },
    AdminOrderCancelInput: {},
    AdminOrderCancellationUpdateInput: {},
    AdminOrderCreateInput: {
        createdAt: "DateTime",
        customer: "IdInput",
        lineItems: "AdminLineItemCreateInput",
        payments: "AdminPaymentCreateWithoutOrderInput",
        salesResponsible: "IdInput",
        source: "OrderSource",
        type: "ProductType"
    },
    AdminOrderSortInput: {
        createdAt: "SortOrder",
        id: "SortOrder",
        orderBalance: "AdminOrderBalanceSortInput",
        totalAmount: "SortOrder",
        updatedAt: "SortOrder"
    },
    AdminOrderUpdateInput: {
        paymentMethod: "PaymentMethod",
        removalAssistant: "IdInput",
        salesResponsible: "IdInput",
        source: "OrderSource"
    },
    AdminOrderWhereInput: {
        AND: "AdminOrderWhereInput",
        NOT: "AdminOrderWhereInput",
        OR: "AdminOrderWhereInput",
        createdAt: "DateTimeFilter",
        customer: "AdminCustomerWhereInput",
        id: "IntFilter",
        lineItems: "AdminLineItemWhereInputListRelationFilter",
        orderBalance: "AdminOrderBalanceWhereInput",
        orderId: "IntFilter",
        orderStatus: "OrderStatus",
        orderTypeStatus: "StringFilter",
        orderTypeStatusUpdatedAt: "DateTimeFilter",
        payments: "AdminPaymentWhereInputListRelationFilter",
        salesResponsibleId: "IntFilter",
        source: "OrderSource",
        timeline: "AdminTimelineEntryWhereInputListRelationFilter"
    },
    AdminOrderWhereInputListRelationFilter: {
        every: "AdminOrderWhereInput",
        none: "AdminOrderWhereInput",
        some: "AdminOrderWhereInput"
    },
    AdminPausePolicyCreateInput: {
        expiresAt: "DateTime"
    },
    AdminPayment: {
        timeline: {
            orderBy: "AdminTimelineEntryOrderInput",
            where: "AdminTimelineEntryWhereInput"
        }
    },
    AdminPaymentCreateInput: {
        order: "IdInput"
    },
    AdminPaymentCreateWithoutOrderInput: {},
    AdminPaymentUpdateInput: {
        status: "PaymentStatus"
    },
    AdminPaymentWhereInput: {
        invoiceId: "IntFilter",
        status: "PaymentFilter",
        timeline: "AdminTimelineEntryWhereInputListRelationFilter"
    },
    AdminPaymentWhereInputListRelationFilter: {
        every: "AdminPaymentWhereInput",
        none: "AdminPaymentWhereInput",
        some: "AdminPaymentWhereInput"
    },
    AdminPayoutCreateInput: {
        staff: "IdInput"
    },
    AdminPayoutWhereInput: {
        staff: "IdInput"
    },
    AdminPricingRuleCreateInput: {},
    AdminPricingRuleUpdateInput: {},
    AdminProduct: {
        basePrice: {}
    },
    AdminProductCreateInput: {
        platform: "IdInput",
        type: "ProductType"
    },
    AdminProductUpdateInput: {
        platform: "IdInput",
        type: "ProductType"
    },
    AdminRemovalEntryCreateInput: {},
    AdminRemovalEntryUpdateInput: {
        notForwardedAt: "DateTime"
    },
    AdminRemovalSubmissionCreateInput: {
        entries: "AdminRemovalEntryCreateInput",
        onlineProfile: "IdInput"
    },
    AdminRemovalSubmissionLawyerUpdateInput: {
        lawyer: "IdInput"
    },
    AdminRemovalSubmissionUpdateInput: {},
    AdminRemovalSubmissionWhereInput: {
        lawyerId: "IntFilter",
        referenceNumber: "StringFilter"
    },
    AdminRoleWhereInput: {
        id: "IntFilter",
        title: "StringFilter"
    },
    AdminStaffCreateInput: {
        gender: "Gender",
        role: "IdInput",
        user: "AdminUserCreateInput"
    },
    AdminStaffOrderInput: {
        active: "SortOrder",
        createdAt: "SortOrder",
        firstName: "SortOrder",
        id: "SortOrder",
        lastName: "SortOrder",
        role: "SortOrder",
        updatedAt: "SortOrder"
    },
    AdminStaffUpdateInput: {
        gender: "Gender",
        role: "IdInput",
        squad: "IdInput",
        user: "AdminUserUpdateInput"
    },
    AdminStaffWhereInput: {
        AND: "AdminStaffWhereInput",
        OR: "AdminStaffWhereInput",
        createdAt: "DateTimeFilter",
        firstName: "StringFilter",
        gender: "Gender",
        lastName: "StringFilter",
        role: "AdminRoleWhereInput",
        user: "AdminUserWhereInput"
    },
    AdminTaskCustomerCreateInput: {
        assignee: "IdInput",
        customer: "IdInput",
        dueAt: "DateTime"
    },
    AdminTaskLeadCreateInput: {
        assignee: "IdInput",
        dueAt: "DateTime",
        lead: "IdInput"
    },
    AdminTaskOrderCreateInput: {
        assignee: "IdInput",
        dueAt: "DateTime",
        order: "IdInput"
    },
    AdminTaskSortInput: {
        dueAt: "SortOrder"
    },
    AdminTaskUpdateInput: {
        assignee: "IdInput",
        dueAt: "DateTime"
    },
    AdminTaskWhereInput: {
        assignee: "IdInput",
        completedAt: "DateTimeFilter",
        dueAt: "DateTimeFilter",
        timelineEntry: "AdminTimelineEntryWhereInput"
    },
    AdminTemplateVariableUpdateInput: {},
    AdminTimelineEntryOrderInput: {
        createdAt: "SortOrder",
        isPinned: "SortOrder",
        status: "AdminTimelineEntryStatusOrderInput"
    },
    AdminTimelineEntryStatusOrderInput: {
        effectiveAt: "SortOrder",
        isUpcoming: "SortOrder"
    },
    AdminTimelineEntryStatusWhereInput: {},
    AdminTimelineEntryUpdateInput: {},
    AdminTimelineEntryWhereInput: {
        NOT: "AdminTimelineEntryWhereInput",
        OR: "AdminTimelineEntryWhereInput",
        customer: "IdInput",
        lead: "IdInput",
        mailEvent: "AdminEventMailWhereInput",
        order: "IdInput",
        status: "AdminTimelineEntryStatusWhereInput",
        type: "EventType"
    },
    AdminTimelineEntryWhereInputListRelationFilter: {
        every: "AdminTimelineEntryWhereInput",
        none: "AdminTimelineEntryWhereInput",
        some: "AdminTimelineEntryWhereInput"
    },
    AdminUserCreateInput: {},
    AdminUserUpdateInput: {},
    AdminUserWhereInput: {},
    CallDirectionOptions: "enum",
    CallOutcome: "enum",
    Customer: {
        orders: {
            orderBy: "AdminOrderSortInput",
            where: "AdminOrderWhereInput"
        }
    },
    DateTime: `scalar.DateTime`,
    DateTimeFilter: {
        equals: "DateTime",
        gt: "DateTime",
        gte: "DateTime",
        in: "DateTime",
        lt: "DateTime",
        lte: "DateTime",
        not: "DateTimeFilter",
        notIn: "DateTime"
    },
    EmailInput: {
        attachments: "EmailInputAttachment"
    },
    EmailInputAttachment: {},
    EmailOptions: "enum",
    EmailReplyOrForwardInput: {
        attachments: "EmailInputAttachment"
    },
    EventType: "enum",
    FloatFilter: {
        not: "FloatFilter"
    },
    Gender: "enum",
    IdInput: {},
    IntFilter: {
        not: "IntFilter"
    },
    KPI: "enum",
    Location: {
        avgScore: {
            ratings: "ReviewRating",
            timeframe: "TimeframeFilter"
        },
        isIntegrationReady: {},
        reviewsCount: {
            ratings: "ReviewRating",
            timeframe: "TimeframeFilter"
        }
    },
    Mutation: {
        _acceptOfferFromStripeSession: {},
        _deleteOfferFromStripeSession: {},
        _remindOffers: {},
        _sendM1Reminder: {},
        _updateCustomerLocationCategory: {},
        _updateOnlineProfilePotential: {},
        acceptOffer: {},
        bulkReassignCustomers: {
            where: "AdminCustomerWhereInput"
        },
        bulkReassignLeads: {
            where: "AdminLeadWhereInput"
        },
        bulkUpdateLeadsKeyAccount: {
            orderBy: "AdminLeadSortInput",
            where: "AdminLeadWhereInput"
        },
        bulkUpdateRemovalEntryStatus: {
            id: "IntFilter",
            status: "RemovalEntryStatus"
        },
        cancelOrder: {
            data: "AdminOrderCancelInput"
        },
        completeSupplyingCustomer: {},
        completeTask: {},
        connectGoogleMyBusiness: {},
        crawlCustomer: {},
        crawlOnlineProfile: {},
        createBillingAddress: {
            data: "AdminBillingAddressCreateInput"
        },
        createBulkStornoPayment: {},
        createCustomer: {
            data: "AdminCustomerCreateInput"
        },
        createInstallmentPayments: {
            data: "AdminInstallmentPaymentsCreateInput"
        },
        createInvoice: {},
        createLawyer: {
            data: "AdminLawyerCreateData"
        },
        createLeadLoss: {
            data: "AdminLeadLossInput"
        },
        createLocation: {
            data: "AdminLocationCreateInput"
        },
        createLogCustomer: {
            data: "AdminCustomerLogEventCreateInput"
        },
        createLogLead: {
            data: "AdminLeadLogEventCreateInput"
        },
        createNoteCustomer: {
            data: "AdminEventCustomerNoteCreateData"
        },
        createNoteLead: {
            data: "AdminEventLeadNoteCreateData"
        },
        createNoteOrder: {
            data: "AdminEventOrderNoteCreateData"
        },
        createOffer: {
            data: "AdminOfferCreateInput"
        },
        createOfferFromCustomerPortal: {
            items: "PublicCartItem"
        },
        createOfferFromStripeSession: {
            data: "AdminOfferWebsiteCreateInput"
        },
        createOfflineCampaign: {
            data: "AdminLeadOfflineCampaignCreateInput"
        },
        createOnlineProfile: {
            data: "AdminOnlineProfileCreateInput"
        },
        createOrder: {
            data: "AdminOrderCreateInput"
        },
        createPausePolicy: {
            data: "AdminPausePolicyCreateInput"
        },
        createPayment: {
            data: "AdminPaymentCreateInput"
        },
        createPayout: {
            data: "AdminPayoutCreateInput"
        },
        createPricingRule: {
            data: "AdminPricingRuleCreateInput"
        },
        createProduct: {
            data: "AdminProductCreateInput"
        },
        createStaff: {
            data: "AdminStaffCreateInput"
        },
        createStornoPayment: {},
        createTaskCustomer: {
            data: "AdminTaskCustomerCreateInput"
        },
        createTaskLead: {
            data: "AdminTaskLeadCreateInput"
        },
        createTaskOrder: {
            data: "AdminTaskOrderCreateInput"
        },
        createUserForCustomer: {},
        deleteBillingAddress: {},
        deleteLawyer: {},
        deleteLocation: {},
        deleteOnlineProfile: {},
        deletePausePolicy: {},
        deletePricingRule: {},
        deleteProduct: {},
        deleteTimelineEntry: {},
        disconnectGoogleMyBusiness: {},
        forgotPassword: {},
        googleMyBusinessDeleteReview: {},
        googleMyBusinessReplyToReview: {},
        importGoogleLead: {},
        login: {},
        markNotForwardedReport: {
            file: "Upload"
        },
        mergeCustomers: {},
        moveProductPricingCategory: {},
        publicScanLink: {},
        publicScanPlaceId: {},
        removalSubmissionExtractOnHold: {},
        replyOrForwardGmail: {
            customer: "IdInput",
            data: "EmailReplyOrForwardInput",
            lead: "IdInput"
        },
        scanLink: {},
        scanPlaceId: {},
        sendCustomerPortalCreds: {
            customer: "IdInput",
            data: "EmailInput"
        },
        sendEmailInvoice: {
            data: "EmailInput",
            payment: "IdInput"
        },
        sendEmailOrderConfirmation: {
            data: "EmailInput",
            order: "IdInput"
        },
        sendEmailOwnerCommentsReminder: {
            data: "EmailInput",
            order: "IdInput"
        },
        sendEmailPowerOfAttorney: {
            data: "EmailInput",
            order: "IdInput"
        },
        sendEmailPowerOfAttorneyGoogle: {
            customer: "IdInput",
            data: "EmailInput"
        },
        sendEmailReminder1: {
            data: "EmailInput",
            payment: "IdInput"
        },
        sendEmailReminder2: {
            data: "EmailInput",
            payment: "IdInput"
        },
        sendGmail: {
            customer: "IdInput",
            data: "EmailInput",
            lead: "IdInput",
            offer: "IdInput"
        },
        startOutboundCall: {
            customer: "IdInput",
            lead: "IdInput"
        },
        supplyCustomer: {},
        toggleTemplateVisibility: {
            emailTemplate: "IdInput"
        },
        updateBillingAddress: {
            data: "AdminBillingAddressUpdateInput"
        },
        updateCustomer: {
            data: "AdminCustomerUpdateInput"
        },
        updateLawyer: {
            data: "AdminLawyerUpdateData"
        },
        updateLead: {
            data: "AdminLeadUpdateInput"
        },
        updateLeadAllocationRuleRegion: {
            data: "AdminLeadAllocationRuleRegionUpdateInput"
        },
        updateLeadCategory: {},
        updateLocation: {
            data: "AdminLocationUpdateInput"
        },
        updateLogCustomer: {
            data: "AdminLogEventUpdateInput"
        },
        updateLogLead: {
            data: "AdminLogEventUpdateInput"
        },
        updateNoteCustomer: {
            data: "AdminEventNoteUpdateData"
        },
        updateNoteLead: {
            data: "AdminEventNoteUpdateData"
        },
        updateNoteOrder: {
            data: "AdminEventNoteUpdateData"
        },
        updateOffer: {
            data: "AdminOfferUpdateInput"
        },
        updateOfferStatus: {
            status: "OfferStatus"
        },
        updateOnlineProfile: {
            data: "AdminOnlineProfileUpdateInput"
        },
        updateOrder: {
            data: "AdminOrderUpdateInput"
        },
        updateOrderCancellation: {
            data: "AdminOrderCancellationUpdateInput"
        },
        updatePayment: {
            data: "AdminPaymentUpdateInput"
        },
        updatePaymentStatusFromCSV: {
            file: "Upload"
        },
        updatePricingRule: {
            data: "AdminPricingRuleUpdateInput"
        },
        updateProduct: {
            data: "AdminProductUpdateInput"
        },
        updateRemovalEntry: {
            data: "AdminRemovalEntryUpdateInput"
        },
        updateRemovalEntryStatus: {
            status: "RemovalEntryStatus"
        },
        updateRemovalEntryStatusFromCSV: {
            file: "Upload"
        },
        updateRemovalSubmission: {
            data: "AdminRemovalSubmissionUpdateInput"
        },
        updateRemovalSubmissionLawyer: {
            data: "AdminRemovalSubmissionLawyerUpdateInput"
        },
        updateRemovalSubmissionLawyerTexts: {
            lawyer: "IdInput"
        },
        updateScanCache: {},
        updateSquadLead: {},
        updateStaff: {
            data: "AdminStaffUpdateInput"
        },
        updateStaffGmailSignature: {},
        updateTaskCustomer: {
            data: "AdminTaskUpdateInput"
        },
        updateTaskLead: {
            data: "AdminTaskUpdateInput"
        },
        updateTaskOrder: {
            data: "AdminTaskUpdateInput"
        },
        updateTemplateVariable: {
            data: "AdminTemplateVariableUpdateInput"
        },
        updateTimelineEntry: {
            data: "AdminTimelineEntryUpdateInput"
        }
    },
    OfferStatus: "enum",
    OrderSource: "enum",
    OrderStatus: "enum",
    OrderTypeStatus: "enum",
    PaymentFilter: {
        equals: "PaymentStatus",
        in: "PaymentStatus",
        notIn: "PaymentStatus"
    },
    PaymentMethod: "enum",
    PaymentStatus: "enum",
    PaymentType: "enum",
    Permission: "enum",
    Platform: {
        basePrice: {},
        reviewsCount: {
            ratings: "ReviewRating",
            timeframe: "TimeframeFilter"
        }
    },
    ProductType: "enum",
    PublicCartItem: {},
    PublicLineItemsWhereInput: {
        status: "RemovalEntryStatus"
    },
    PublicOrder: {
        removalEntries: {
            where: "PublicLineItemsWhereInput"
        }
    },
    Query: {
        _locationsByIds: {},
        activityCallDuration: {
            callDirection: "CallDirectionOptions",
            emailDirection: "EmailOptions",
            endAt: "DateTime",
            startAt: "DateTime"
        },
        activityOverview: {
            callDirection: "CallDirectionOptions",
            emailDirection: "EmailOptions",
            endAt: "DateTime",
            startAt: "DateTime"
        },
        allCustomers: {
            orderBy: "AdminCustomerSortInput",
            where: "AdminCustomerWhereInput"
        },
        allEmailTemplates: {
            where: "AdminEmailTemplateWhereInput"
        },
        allKeywords: {
            where: "ReviewWhereInput"
        },
        allLawyers: {
            where: "AdminLawyerWhereInput"
        },
        allOffers: {
            where: "AdminOfferWhereInput"
        },
        allOnlineProfiles: {
            where: "AdminOnlineProfileWhereInput"
        },
        allOrders: {
            orderBy: "AdminOrderSortInput",
            where: "AdminOrderWhereInput"
        },
        allPayoutsForStaff: {
            where: "AdminPayoutWhereInput"
        },
        allPlatforms: {},
        allPriceCategories: {},
        allProducts: {},
        allReviews: {
            orderBy: "ReviewSortInput",
            where: "ReviewWhereInput"
        },
        allReviewsIds: {
            orderBy: "ReviewSortInput",
            where: "ReviewWhereInput"
        },
        allRoles: {
            where: "AdminRoleWhereInput"
        },
        allScans: {},
        allStaff: {
            orderBy: "AdminStaffOrderInput",
            where: "AdminStaffWhereInput"
        },
        allTimelineEntries: {
            orderBy: "AdminTimelineEntryOrderInput",
            where: "AdminTimelineEntryWhereInput"
        },
        basePrice: {},
        billedStats: {
            endAt: "DateTime",
            platform: "IdInput",
            startAt: "DateTime"
        },
        celloTokenGuest: {},
        customer: {},
        customerAgeStats: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        customerBreakdownByPeriod: {
            period: "SalesStatsPeriodOption"
        },
        customerCrawlStatus: {},
        customerStats: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        customerSupplyStats: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        customersTotalNegativeReviewsCount: {},
        duplicateRemovalEntries: {},
        entriesWithIds: {},
        exportOrders: {
            where: "AdminOrderWhereInput"
        },
        getDuplicateLocations: {},
        googleForwardedBreakdownByPeriod: {
            period: "SalesStatsPeriodOption"
        },
        googleMyBusinessLocations: {},
        googleRollAvgBreakdownByPeriod: {},
        lead: {},
        leadCategories: {
            where: "AdminLeadCategoryInput"
        },
        leadLossStats: {
            endAt: "DateTime",
            manager: "IdInput",
            startAt: "DateTime"
        },
        leadStatsAvgDaysWonLost: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        leadStatuses: {
            where: "AdminLeadWhereInput"
        },
        leaderboardMonthlySales: {},
        leaderboardMostCustomers: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        leaderboardSquads: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        leaderboardTopPerformers: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        leads: {
            orderBy: "AdminLeadSortInput",
            where: "AdminLeadWhereInput"
        },
        leadsIds: {
            where: "AdminLeadWhereInput"
        },
        loadEmailTemplate: {
            customer: "IdInput",
            lead: "IdInput",
            offer: "IdInput"
        },
        locationCategoriesStats: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        mailEvent: {},
        mailEventAttachment: {},
        myDelegatedTasks: {
            orderBy: "AdminTaskSortInput",
            where: "AdminTaskWhereInput"
        },
        myEmailTemplates: {
            where: "AdminEmailTemplateWhereInput"
        },
        myLocations: {},
        myOrders: {},
        myTasks: {
            orderBy: "AdminTaskSortInput",
            where: "AdminTaskWhereInput"
        },
        offer: {},
        offerById: {},
        offerCustomer: {
            data: "AdminOfferLegacyInput"
        },
        offerLead: {
            data: "AdminOfferLegacyInput"
        },
        offerOverview: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        offlineCampaign: {},
        offlineCampaignLeads: {
            orderBy: "AdminLeadSortInput",
            where: "AdminLeadWhereInput"
        },
        onlineProfile: {},
        openAIKununuPrompt: {},
        order: {},
        ownSalesBreakdownByPeriod: {
            metric: "KPI",
            period: "SalesStatsPeriodOption",
            product: "IdInput"
        },
        payment: {},
        payoutRelatedTimelineEntries: {},
        place: {},
        platformStats: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        platformsBreakdownByPeriod: {
            period: "SalesStatsPeriodOption"
        },
        pricedCart: {
            items: "PublicCartItem"
        },
        productsOverview: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        publicOrder: {},
        publicReviews: {},
        referralCampaign: {},
        removalsStats: {
            endAt: "DateTime",
            platform: "IdInput",
            removalStatuses: "RemovalEntryStatus",
            startAt: "DateTime"
        },
        reportCreatedInvoices: {
            endDate: "DateTime",
            startDate: "DateTime"
        },
        reportCustomerReviews: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        reportFactoringInvoices: {
            endDate: "DateTime",
            startDate: "DateTime"
        },
        reportInitiatedForwarded: {
            statuses: "RemovalEntryStatus"
        },
        reportNotForwarded: {},
        reportOrderOverview: {},
        reviewCountProgress: {
            timeframe: "TimeframeFilter"
        },
        salesBreakdownByPeriod: {
            period: "SalesStatsPeriodOption",
            product: "IdInput",
            salesResponsible: "IdInput",
            source: "OrderSource"
        },
        salesByAgents: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        salesCompareByPeriod: {
            metric: "KPI",
            period: "SalesStatsPeriodOption",
            product: "IdInput"
        },
        scanCache: {},
        scoreOptimization: {},
        scoreProgress: {
            timeframe: "TimeframeFilter"
        },
        scoreProgress6Months: {},
        sourcesOverview: {
            endAt: "DateTime",
            startAt: "DateTime"
        },
        staff: {},
        statsOverview: {
            where: "ReviewWhereInput"
        },
        weeklyReviewSummary: {
            timeframe: "TimeframeFilter"
        }
    },
    RemovalEntryStatus: "enum",
    ReviewRating: "enum",
    ReviewSortInput: {
        date: "SortOrder",
        normalizedScore: "SortOrder"
    },
    ReviewWhereInput: {
        AND: "ReviewWhereInput",
        OR: "ReviewWhereInput",
        ratings: "ReviewRating",
        timeframe: "TimeframeFilter"
    },
    SalesStatsPeriodOption: "enum",
    ScanStatus: "enum",
    SortOrder: "enum",
    StringFilter: {
        mode: "StringFilterMode",
        not: "StringFilter"
    },
    StringFilterMode: "enum",
    StringIdInput: {},
    StringListFilter: {},
    TemplateVariableKey: "enum",
    TimeframeFilter: {},
    Upload: `scalar.Upload`
};
export const ReturnTypes = {
    AdminAgentActivityData: {
        callsCount: "Float",
        emailsCount: "Float",
        name: "String",
        totalCount: "Float"
    },
    AdminAgentCallDurationData: {
        avgCallDuration: "Float",
        name: "String",
        totalCallDuration: "Float"
    },
    AdminAttachment: {
        filename: "String",
        key: "String",
        url: "String"
    },
    AdminBillingAddress: {
        address: "String",
        city: "String",
        companyName: "String",
        country: "String",
        createdAt: "DateTime",
        crefo: "String",
        customDueDays: "Int",
        customInvoiceText: "String",
        email: "String",
        firstName: "String",
        gender: "Gender",
        id: "Int",
        lastName: "String",
        updatedAt: "DateTime",
        vat: "String",
        zipCode: "String"
    },
    AdminBillingStats: {
        amount: "Float",
        count: "Int",
        status: "PaymentStatus"
    },
    AdminCategoryStats: {
        category: "String",
        categoryCount: "Int",
        categoryRatio: "Float",
        spend: "Float",
        totalSpendRatio: "Float"
    },
    AdminCategoryStatsList: {
        categories: "AdminCategoryStats"
    },
    AdminCustomerAgeStatsMetric: {
        afterYear1: "Float",
        manager: "String",
        managerId: "Int",
        month1: "Float",
        month1to3: "Float",
        month3to6: "Float",
        month6to12: "Float",
        newCustomerCount: "Int",
        totalSales: "Float"
    },
    AdminCustomerAgeStatsMetrics: {
        managers: "AdminCustomerAgeStatsMetric",
        total: "AdminCustomerAgeStatsMetric"
    },
    AdminCustomerPaginatedList: {
        items: "Customer",
        totalCount: "Float"
    },
    AdminCustomerStats: {
        customerName: "String",
        lastOrderAt: "String",
        managerName: "String",
        orderCount: "Int",
        paid: "Float",
        removalCount: "Int",
        sales: "Float"
    },
    AdminCustomerStatsList: {
        customers: "AdminCustomerStats"
    },
    AdminCustomerStatsOverview: {
        delta: "Float",
        period: "String",
        price: "Boolean",
        priorPeriodValue: "Float",
        type: "String",
        value: "Float"
    },
    AdminCustomerSupplyStats: {
        name: "String",
        totalCount: "Int"
    },
    AdminDataset: {
        data: "Float",
        label: "String"
    },
    AdminDocumentTemplate: {
        attachments: "AdminAttachment",
        content: "String",
        signature: "String",
        subject: "String",
        to: "String"
    },
    AdminEmailTemplate: {
        attachmentRequired: "Boolean",
        id: "Int",
        isHiddenForMe: "Boolean",
        mustache: "String",
        name: "String",
        subject: "String"
    },
    AdminEventCall: {
        direction: "String",
        duration: "Float",
        endedAt: "DateTime",
        id: "Int",
        link: "String",
        missedCallReason: "String",
        startedAt: "DateTime"
    },
    AdminEventLog: {
        callOutcome: "CallOutcome",
        date: "DateTime",
        description: "String",
        id: "Int",
        timelineEntry: "AdminTimelineEntry"
    },
    AdminEventMail: {
        gmailFrom: "String",
        gmailMessageFull: "AdminGmailMessage",
        gmailMessageId: "String",
        gmailThreadId: "String",
        id: "Int",
        snippet: "String",
        subject: "String",
        trackingId: "String",
        trackingStatus: "String"
    },
    AdminEventNote: {
        content: "String",
        id: "Int",
        timelineEntry: "AdminTimelineEntry"
    },
    AdminEventUpdate: {
        field: "String",
        id: "Int",
        isCreationEvent: "Boolean",
        newValue: "String",
        oldValue: "String"
    },
    AdminGmailMessage: {
        attachments: "AdmingGmailAttachment",
        content: "String",
        customerId: "Int",
        from: "String",
        gmailThreadId: "String",
        id: "String",
        inReplyTo: "String",
        leadId: "Int",
        references: "String",
        subject: "String",
        to: "String"
    },
    AdminLawyer: {
        archivedAt: "DateTime",
        createdAt: "DateTime",
        email: "String",
        id: "Int",
        name: "String",
        platform: "Platform",
        updatedAt: "DateTime"
    },
    AdminLead: {
        address: "String",
        assignedAt: "DateTime",
        categoryName: "String",
        city: "String",
        companyName: "String",
        country: "String",
        createdAt: "DateTime",
        defaultOfferListPrice: "Float",
        email: "String",
        firstName: "String",
        gender: "Gender",
        googleScore: "Float",
        googleUrl: "String",
        id: "Int",
        isKeyAccount: "Boolean",
        lastName: "String",
        lastNote: "AdminEventNote",
        leadEmailPreview: "AdminDocumentTemplate",
        leadLoss: "AdminLeadLoss",
        leadgenId: "Int",
        manager: "AdminStaff",
        negativeReviewsCount: "Int",
        nextTask: "AdminTask",
        offlineCampaign: "AdminLeadOfflineCampaign",
        parentCategoryName: "String",
        phone: "String",
        placeId: "String",
        reviewsCount: "Int",
        status: "AdminLeadStatus",
        updatedAt: "DateTime",
        website: "String",
        zipCode: "String"
    },
    AdminLeadAllocationRuleRegion: {
        active: "Boolean",
        id: "Int",
        region: "String"
    },
    AdminLeadCategory: {
        active: "Boolean",
        allocationMaxScore: "Float",
        allocationMinReviews: "Int",
        assignedLeads: "Int",
        name: "String",
        totalLeads: "Int",
        unassignedLeads: "Int"
    },
    AdminLeadCategoryPaginatedList: {
        items: "AdminLeadCategory",
        totalCount: "Float"
    },
    AdminLeadId: {
        id: "Int"
    },
    AdminLeadLoss: {
        id: "Int",
        reason: "String"
    },
    AdminLeadLossMetric: {
        lostCount: "Float",
        reason: "String"
    },
    AdminLeadLossMetricData: {
        count: "Float",
        lossMetrics: "AdminLeadLossMetric"
    },
    AdminLeadOfflineCampaign: {
        id: "Int",
        leads: "AdminLead",
        title: "String"
    },
    AdminLeadPaginatedList: {
        items: "AdminLead",
        totalCount: "Int"
    },
    AdminLeadStatusAggregate: {
        status: "AdminLeadStatus",
        totalLeads: "Int"
    },
    AdminLeadsWonLostMetric: {
        avgDaysToLost: "Float",
        avgDaysToWon: "Float",
        lostCount: "Float",
        manager: "String",
        managerId: "Int",
        percentWon: "Float",
        wonCount: "Float"
    },
    AdminLineData: {
        datasets: "AdminDataset",
        labels: "String"
    },
    AdminLineItem: {
        createdAt: "DateTime",
        customTitle: "String",
        id: "Int",
        lawyer: "AdminLawyer",
        price: "Float",
        product: "AdminProduct",
        quantity: "Float",
        removalSubmission: "AdminRemovalSubmission"
    },
    AdminLocationCustomerId: {
        customerId: "Int"
    },
    AdminLocationPaginatedList: {
        items: "Location",
        totalCount: "Float"
    },
    AdminManagerStats: {
        customerCount: "Int",
        customerRatio: "Float",
        name: "String"
    },
    AdminManagerStatsList: {
        managers: "AdminManagerStats"
    },
    AdminOffer: {
        author: "AdminOfferAuthor",
        companyName: "String",
        createdAt: "DateTime",
        customer: "Customer",
        discountCode: "String",
        expiresAt: "DateTime",
        expiresInDays: "Int",
        gender: "Gender",
        id: "Int",
        isUnderRevision: "Boolean",
        lastName: "String",
        lastStaffUpdateAt: "DateTime",
        lastStatusUpdateAt: "DateTime",
        lead: "AdminLead",
        lineItems: "AdminOfferLineItem",
        locationsCount: "Int",
        reviewCount: "Int",
        slug: "String",
        source: "String",
        status: "OfferStatus",
        taxPercent: "Float",
        totalAmount: "Float",
        updatedAt: "DateTime"
    },
    AdminOfferAuthor: {
        avatar: "String",
        jobTitle: "String",
        name: "String",
        phone: "String",
        sendoutEmail: "String"
    },
    AdminOfferLineItem: {
        id: "Int",
        listPrice: "Float",
        mapsLink: "String",
        onlineProfileId: "Int",
        onlineProfileLink: "String",
        platform: "Platform",
        platformId: "Int",
        reviewIds: "String",
        scanCacheId: "String",
        title: "String",
        unitPrice: "Float"
    },
    AdminOfferOverviewStats: {
        offers: "AdminOfferStats",
        total: "AdminOfferStats"
    },
    AdminOfferPaginatedList: {
        items: "AdminOffer",
        totalCount: "Float"
    },
    AdminOfferStats: {
        amount: "Float",
        cancelledOffers: "Int",
        createdOffers: "Int",
        expiredCount: "Int",
        name: "String",
        netCreated: "Int",
        pendingCount: "Int",
        wonCount: "Int",
        wonRate: "Float",
        wonTimeDays: "Float",
        wonValue: "Int"
    },
    AdminOrder: {
        billableAmount: "Float",
        billableBalance: "Float",
        commission: "Float",
        confirmationEmailPreview: "AdminDocumentTemplate",
        createdAt: "DateTime",
        crefo: "String",
        customer: "Customer",
        customerConfirmation: "String",
        hasInstallmentPayments: "Boolean",
        hasUnwoundLineItems: "Boolean",
        id: "Int",
        isBillingPaused: "Boolean",
        isOrderConfirmationSent: "Boolean",
        isPowerOfAttorneySent: "Boolean",
        lineItems: "AdminLineItem",
        orderCancellation: "AdminOrderCancellation",
        orderId: "String",
        orderLocation: "String",
        orderStatus: "OrderStatus",
        orderTypeStatus: "OrderTypeStatus",
        orderTypeStatusUpdatedAt: "DateTime",
        ownerCommentsReminderEmailPreview: "AdminDocumentTemplate",
        paymentInvoiceIds: "String",
        paymentMethod: "PaymentMethod",
        paymentStatus: "PaymentStatus",
        paymentSummary: "PaymentSummaryRemovalOrder",
        payments: "AdminPayment",
        powerOfAttorney: "String",
        powerOfAttorneyOrderEmailPreview: "AdminDocumentTemplate",
        price: "Float",
        product: "AdminProduct",
        quantity: "Int",
        removalAssistant: "AdminStaff",
        salesResponsible: "AdminStaff",
        slug: "String",
        source: "OrderSource",
        timeline: "AdminTimelinePaginatedList",
        totalAmount: "Float",
        type: "ProductType",
        updatedAt: "DateTime"
    },
    AdminOrderCancellation: {
        id: "Int",
        note: "String",
        reason: "String"
    },
    AdminOrderPaginatedList: {
        items: "AdminOrder",
        totalCount: "Float"
    },
    AdminOverallPlatformStats: {
        platforms: "AdminSinglePlatformStats",
        totalPlatformStats: "AdminTotalPlatformStats"
    },
    AdminPausePolicy: {
        author: "AdminStaff",
        createdAt: "DateTime",
        expiresAt: "DateTime",
        id: "Int",
        platforms: "Platform",
        reason: "String"
    },
    AdminPayment: {
        amount: "Float",
        createdAt: "DateTime",
        id: "Int",
        invoiceEmailPreview: "AdminDocumentTemplate",
        invoiceId: "String",
        invoiceUrl: "String",
        isInvoiceSent: "Boolean",
        isReminder1Sent: "Boolean",
        isReminder2Sent: "Boolean",
        orderId: "Int",
        reminder1EmailPreview: "AdminDocumentTemplate",
        reminder2EmailPreview: "AdminDocumentTemplate",
        status: "PaymentStatus",
        stornoInvoiceId: "String",
        timeline: "AdminTimelineEntry",
        type: "PaymentType",
        updatedAt: "DateTime"
    },
    AdminPayout: {
        amount: "Float",
        createdAt: "DateTime",
        id: "Int",
        initiator: "AdminStaff",
        note: "String",
        staff: "AdminStaff"
    },
    AdminPayoutPaginatedList: {
        items: "AdminPayout",
        totalCount: "Float"
    },
    AdminPlace: {
        customerId: "Int",
        leadId: "Int"
    },
    AdminPlatformPotential: {
        icon: "String",
        id: "String",
        potential: "Int",
        title: "String"
    },
    AdminPricingCategory: {
        id: "Float",
        pricingRules: "AdminPricingRule",
        products: "AdminProduct"
    },
    AdminPricingRule: {
        id: "Int",
        maxUnits: "Int",
        minUnits: "Int",
        pricingCategoryId: "Int",
        unitPrice: "Float"
    },
    AdminProduct: {
        basePrice: "Float",
        createdAt: "DateTime",
        id: "Int",
        invoiceTitle: "String",
        platform: "Platform",
        priceCategory: "Float",
        title: "String",
        type: "ProductType",
        updatedAt: "DateTime"
    },
    AdminReferralCampaign: {
        userId: "String"
    },
    AdminRemovalEntry: {
        comment: "String",
        id: "Int",
        noText: "Boolean",
        notForwardedAt: "DateTime",
        orderId: "Int",
        pseudonym: "Boolean",
        recent: "Boolean",
        removalRequest: "String",
        review: "Review",
        reviewId: "String",
        reviewText: "String",
        reviewerLink: "String",
        reviewerName: "String",
        status: "RemovalEntryStatus",
        transitionHistory: "AdminRemovalEntryStatusTransition"
    },
    AdminRemovalEntryStatusTransition: {
        age: "Float",
        createdAt: "DateTime",
        id: "Int",
        staff: "AdminStaff",
        statusEnd: "RemovalEntryStatus",
        statusStart: "RemovalEntryStatus"
    },
    AdminRemovalStats: {
        amount: "Float",
        count: "Int",
        status: "RemovalEntryStatus"
    },
    AdminRemovalSubmission: {
        entries: "AdminRemovalEntry",
        id: "Int",
        lawyer: "AdminLawyer",
        onlineProfile: "OnlineProfile",
        proofScreenshot: "String",
        proofVideo: "String",
        referenceNumber: "String",
        referenceNumberAt: "DateTime"
    },
    AdminRole: {
        id: "Int",
        permissions: "String",
        title: "String"
    },
    AdminSalesByAgentsMetric: {
        avgRemovalPrice: "Float",
        billableAmount: "Float",
        billableBalance: "Float",
        commissionTotal: "Float",
        firstSale: "Float",
        id: "Int",
        name: "String",
        openAmount: "Float",
        ordersCount: "Int",
        paidSales: "Float",
        removalsCount: "Int",
        sales: "Float",
        secondSales: "Float",
        walletPaid: "Float",
        walletTotal: "Float"
    },
    AdminSalesByAgentsMetrics: {
        staff: "AdminSalesByAgentsMetric",
        total: "AdminSalesByAgentsMetric"
    },
    AdminSalesStats: {
        paidSales: "Float",
        rows: "AdminSalesStatsMetric",
        totalSales: "Float"
    },
    AdminSalesStatsMetric: {
        label: "String",
        paidSales: "Float",
        percentOfTotal: "Float",
        percentOfTotalPaid: "Float",
        sales: "Float"
    },
    AdminSinglePlatformStats: {
        avgCompletionDays: "Int",
        forwardAvgDays: "Int",
        forwardMaxDays: "Int",
        forwardMinDays: "Int",
        forwardRate: "Float",
        id: "Int",
        orderCount: "Int",
        removalCount: "Int",
        removalRate: "Float",
        title: "String"
    },
    AdminSquad: {
        id: "Int",
        lead: "AdminStaff",
        members: "AdminStaff"
    },
    AdminSquadSalesStats: {
        avg: "Float",
        id: "Int",
        totalAmount: "Float"
    },
    AdminSquadStats: {
        sales: "AdminSquadSalesStats",
        squads: "AdminSquad"
    },
    AdminStaff: {
        aircallNumberId: "Int",
        aircallUserId: "Int",
        commissionPercent: "Float",
        createdAt: "DateTime",
        firstName: "String",
        gender: "Gender",
        id: "Int",
        isCustomerAssignable: "Boolean",
        jobTitle: "String",
        lastName: "String",
        name: "String",
        permissions: "String",
        phone: "String",
        role: "AdminRole",
        sendoutEmail: "String",
        squadId: "Int",
        updatedAt: "DateTime",
        user: "AdminUser"
    },
    AdminStaffPaginatedList: {
        items: "AdminStaff",
        totalCount: "Float"
    },
    AdminStatsAbstractStaffMetric: {
        avatar: "String",
        id: "Int",
        name: "String",
        squadId: "Float",
        value: "Float"
    },
    AdminStatsAllProductsOverviewMetric: {
        paidSales: "Float",
        products: "AdminStatsProductOverviewMetric",
        totalSales: "Float"
    },
    AdminStatsProductOverviewMetric: {
        paidSales: "Float",
        percentOfTotal: "Float",
        percentOfTotalPaid: "Float",
        sales: "Float",
        title: "String",
        type: "String"
    },
    AdminStatsSalesOverviewMetric: {
        delta: "Float",
        period: "String",
        priorPeriodValue: "Float",
        value: "Float"
    },
    AdminTask: {
        assignee: "AdminStaff",
        dueAt: "DateTime",
        id: "Int",
        isCompleted: "Boolean",
        note: "String",
        timelineEntry: "AdminTimelineEntry",
        title: "String"
    },
    AdminTaskPaginatedList: {
        items: "AdminTask",
        totalCount: "Float"
    },
    AdminTemplateVariable: {
        id: "Int",
        key: "TemplateVariableKey",
        value: "String"
    },
    AdminTimelineEntry: {
        association: "TimelineEntryEventAssociation",
        associationStatus: "String",
        author: "AdminStaff",
        createdAt: "DateTime",
        event: "TimelineEntryEvent",
        id: "Int",
        isBot: "Boolean",
        isPinned: "Boolean",
        status: "AdminTimelineEntryStatus",
        type: "EventType"
    },
    AdminTimelineEntryStatus: {
        effectiveAt: "DateTime",
        isUpcoming: "Boolean"
    },
    AdminTimelinePaginatedList: {
        items: "AdminTimelineEntry",
        totalCount: "Float"
    },
    AdminTotalPlatformStats: {
        avgCompletionDays: "Int",
        orderCount: "Int",
        removalCount: "Int",
        removalRate: "Int"
    },
    AdminUpdateFromCSV: {
        missingReferenceNumbers: "String",
        updatedCount: "Int"
    },
    AdminUser: {
        active: "Boolean",
        avatar: "String",
        createdAt: "DateTime",
        email: "String",
        id: "Int",
        isOnline: "Boolean",
        lastOnlineAt: "DateTime",
        owner: "UserOwnerUnion",
        pcSerialNumber: "String",
        permissions: "String",
        signature: "String",
        updatedAt: "DateTime"
    },
    AdmingGmailAttachment: {
        attachmentId: "String",
        filename: "String",
        url: "String"
    },
    BaseType: {
        createdAt: "DateTime",
        id: "String",
        updatedAt: "DateTime"
    },
    Customer: {
        billedAmount: "Float",
        billingAddress: "AdminBillingAddress",
        blacklistReason: "String",
        celloToken: "String",
        createdAt: "DateTime",
        currentSupplier: "AdminStaff",
        customerId: "String",
        customerPortalCredentialsPreview: "AdminDocumentTemplate",
        customerPortalPassword: "String",
        defaultLocation: "Location",
        email: "String",
        firstName: "String",
        gender: "Gender",
        hasBadPayments: "Boolean",
        id: "Int",
        isAgency: "Boolean",
        isBlacklisted: "Boolean",
        isPowerOfAttorneyGoogleSent: "Boolean",
        isScrapingAvoided: "Boolean",
        isSubscribedNewsletter: "Boolean",
        lastName: "String",
        lastOrderAt: "DateTime",
        lastSuppliedAt: "DateTime",
        lastSupplier: "AdminStaff",
        locations: "Location",
        locationsCount: "Int",
        manager: "AdminStaff",
        name: "String",
        orderCompletedCount: "Int",
        orderCount: "Int",
        orderInProgressCount: "Int",
        orderVolume: "Float",
        orders: "AdminOrderPaginatedList",
        pausePolicy: "AdminPausePolicy",
        paymentMethod: "PaymentMethod",
        phoneNumbers: "String",
        platformsWithPotential: "AdminPlatformPotential",
        potentialRemovalsCount: "Int",
        powerOfAttorney: "String",
        powerOfAttorneyGoogle: "String",
        powerOfAttorneyGoogleEmailPreview: "AdminDocumentTemplate",
        pricingCategories: "AdminPricingCategory",
        removalsPartOfOrderCount: "Int",
        supportHandoverAt: "DateTime",
        totalNegativeReviewsCount: "Int",
        totalPotential: "Int",
        updatedAt: "DateTime",
        user: "AdminUser"
    },
    DataSet: {
        data: "ScoreDataPoint"
    },
    DateTime: `scalar.DateTime`,
    GoogleMyBusinessLocation: {
        address: "String",
        link: "String",
        newReviewLink: "String",
        placeId: "String",
        platformSpecificId: "String",
        title: "String"
    },
    Keyword: {
        count: "Int",
        id: "String",
        sentiment: "ReviewRating",
        value: "String"
    },
    LineData: {
        datasets: "DataSet",
        labels: "String"
    },
    Location: {
        address: "String",
        avgScore: "Float",
        billingAddress: "AdminBillingAddress",
        city: "String",
        country: "String",
        createdAt: "DateTime",
        id: "Int",
        isIntegrationReady: "Boolean",
        lastSuccessfulScanAt: "DateTime",
        mapsLink: "String",
        onlineProfiles: "OnlineProfile",
        phone: "String",
        placeId: "String",
        reviewsCount: "Int",
        title: "String",
        updatedAt: "DateTime",
        website: "String",
        zipCode: "String"
    },
    LoginResult: {
        accessToken: "String"
    },
    Mutation: {
        _acceptOfferFromStripeSession: "Boolean",
        _clearExpiredPausePolicies: "Boolean",
        _createM1AutomationJobs: "Boolean",
        _deleteOfferFromStripeSession: "Boolean",
        _expireOffers: "Boolean",
        _remindOffers: "Int",
        _removeCustomerManagers: "Boolean",
        _sendM1Reminder: "Boolean",
        _updateCustomerLocationCategory: "Customer",
        _updateOnlineProfilePotential: "OnlineProfile",
        acceptOffer: "AdminOffer",
        allocateLead: "AdminLead",
        bulkReassignCustomers: "Int",
        bulkReassignLeads: "Int",
        bulkUpdateLeadsKeyAccount: "Int",
        bulkUpdateRemovalEntryStatus: "AdminRemovalEntry",
        cancelOrder: "AdminOrder",
        completeSupplyingCustomer: "Customer",
        completeTask: "AdminTask",
        connectGoogleMyBusiness: "Boolean",
        crawlCustomer: "Int",
        crawlOnlineProfile: "Boolean",
        crawlScheduledOnlineProfiles: "Int",
        createBillingAddress: "AdminBillingAddress",
        createBulkStornoPayment: "AdminPayment",
        createCustomer: "Customer",
        createInstallmentPayments: "AdminPayment",
        createInvoice: "AdminPayment",
        createLawyer: "AdminLawyer",
        createLeadLoss: "AdminLead",
        createLocation: "Location",
        createLogCustomer: "AdminEventLog",
        createLogLead: "AdminEventLog",
        createNoteCustomer: "AdminEventNote",
        createNoteLead: "AdminEventNote",
        createNoteOrder: "AdminEventNote",
        createOffer: "AdminOffer",
        createOfferFromCustomerPortal: "AdminOffer",
        createOfferFromStripeSession: "AdminOffer",
        createOfflineCampaign: "AdminLeadOfflineCampaign",
        createOnlineProfile: "OnlineProfile",
        createOrder: "AdminOrder",
        createPausePolicy: "AdminPausePolicy",
        createPayment: "AdminPayment",
        createPayout: "AdminPayout",
        createPricingRule: "AdminPricingRule",
        createProduct: "AdminProduct",
        createStaff: "AdminStaff",
        createStornoPayment: "AdminPayment",
        createTaskCustomer: "AdminTask",
        createTaskLead: "AdminTask",
        createTaskOrder: "AdminTask",
        createUserForCustomer: "Customer",
        deleteBillingAddress: "Boolean",
        deleteLawyer: "AdminLawyer",
        deleteLocation: "Location",
        deleteOnlineProfile: "OnlineProfile",
        deletePausePolicy: "Boolean",
        deletePricingRule: "Boolean",
        deleteProduct: "AdminProduct",
        deleteTimelineEntry: "AdminTimelineEntry",
        disconnectGoogleMyBusiness: "Boolean",
        forgotPassword: "Boolean",
        googleMyBusinessDeleteReview: "Review",
        googleMyBusinessReplyToReview: "Review",
        importGoogleLead: "Boolean",
        login: "LoginResult",
        logout: "Boolean",
        markNotForwardedReport: "Int",
        mergeCustomers: "Customer",
        moveProductPricingCategory: "AdminPricingCategory",
        publicScanLink: "ScanCache",
        publicScanPlaceId: "ScanCache",
        removalSubmissionExtractOnHold: "AdminRemovalSubmission",
        replyOrForwardGmail: "AdminTimelineEntry",
        requestCustomerReviewsCrawl: "Boolean",
        scanLink: "ScanCache",
        scanPlaceId: "ScanCache",
        sendCustomerPortalCreds: "Customer",
        sendEmailInvoice: "AdminPayment",
        sendEmailOrderConfirmation: "AdminOrder",
        sendEmailOwnerCommentsReminder: "AdminOrder",
        sendEmailPowerOfAttorney: "AdminOrder",
        sendEmailPowerOfAttorneyGoogle: "Customer",
        sendEmailReminder1: "AdminPayment",
        sendEmailReminder2: "AdminPayment",
        sendGmail: "AdminTimelineEntry",
        startOutboundCall: "AdminTimelineEntry",
        supplyCustomer: "Customer",
        toggleTemplateVisibility: "AdminEmailTemplate",
        updateBillingAddress: "AdminBillingAddress",
        updateCustomer: "Customer",
        updateLawyer: "AdminLawyer",
        updateLead: "AdminLead",
        updateLeadAllocationRuleRegion: "AdminLeadAllocationRuleRegion",
        updateLeadCategory: "AdminLeadCategory",
        updateLocation: "Location",
        updateLogCustomer: "AdminEventLog",
        updateLogLead: "AdminEventLog",
        updateNoteCustomer: "AdminEventNote",
        updateNoteLead: "AdminEventNote",
        updateNoteOrder: "AdminEventNote",
        updateOffer: "AdminOffer",
        updateOfferStatus: "AdminOffer",
        updateOnlineProfile: "OnlineProfile",
        updateOrder: "AdminOrder",
        updateOrderCancellation: "AdminOrderCancellation",
        updatePayment: "AdminPayment",
        updatePaymentStatusFromCSV: "AdminUpdateFromCSV",
        updatePricingRule: "AdminPricingRule",
        updateProduct: "AdminProduct",
        updateRemovalEntry: "AdminRemovalEntry",
        updateRemovalEntryStatus: "AdminRemovalEntry",
        updateRemovalEntryStatusFromCSV: "AdminUpdateFromCSV",
        updateRemovalSubmission: "AdminRemovalSubmission",
        updateRemovalSubmissionLawyer: "AdminRemovalSubmission",
        updateRemovalSubmissionLawyerTexts: "AdminRemovalSubmission",
        updateScanCache: "ScanCache",
        updateSquadLead: "AdminSquad",
        updateStaff: "AdminStaff",
        updateStaffGmailSignature: "AdminStaff",
        updateTaskCustomer: "AdminTask",
        updateTaskLead: "AdminTask",
        updateTaskOrder: "AdminTask",
        updateTemplateVariable: "AdminTemplateVariable",
        updateTimelineEntry: "AdminTimelineEntry"
    },
    OnlineProfile: {
        createdAt: "DateTime",
        customerId: "String",
        id: "Int",
        isPaused: "Boolean",
        lastScan: "Scan",
        link: "String",
        location: "Location",
        locationId: "String",
        newReviewLink: "String",
        platform: "Platform",
        platformSlug: "String",
        potentialRemovalsCount: "Int",
        reviews: "Review",
        updatedAt: "DateTime"
    },
    PaymentSummaryRemovalOrder: {
        billableAllTimeCount: "Int",
        billableCurrentCount: "Int",
        lostCount: "Int",
        onHoldCount: "Int",
        openCount: "Int",
        openWithoutOnHoldCount: "Int",
        unitPrice: "Float"
    },
    Platform: {
        basePrice: "Float",
        basePricingRules: "AdminPricingRule",
        icon: "String",
        id: "Int",
        products: "AdminProduct",
        reviewsCount: "Int",
        slug: "String",
        title: "String"
    },
    PublicCustomer: {
        companyName: "String",
        gender: "Gender",
        lastName: "String"
    },
    PublicCustomerReferrer: {
        celloToken: "String",
        email: "String",
        firstName: "String",
        lastName: "String"
    },
    PublicLineItem: {
        id: "Int",
        onlineProfile: "PublicOnlineProfile",
        platform: "Platform",
        quantity: "Float"
    },
    PublicOnlineProfile: {
        id: "Int",
        link: "String",
        title: "String"
    },
    PublicOrder: {
        billableAmount: "Float",
        createdAtDate: "String",
        customer: "PublicCustomer",
        id: "Int",
        lineItems: "PublicLineItem",
        orderId: "String",
        orderStatus: "OrderStatus",
        orderStatusPageLink: "String",
        paymentSummary: "PaymentSummaryRemovalOrder",
        quantity: "Float",
        removalEntries: "PublicRemovalEntry",
        salesResponsible: "PublicOrderSalesResponsible",
        totalAmount: "Float"
    },
    PublicOrderPaginatedList: {
        items: "PublicOrder",
        totalCount: "Float"
    },
    PublicOrderSalesResponsible: {
        avatar: "String",
        jobTitle: "String",
        name: "String",
        phone: "String",
        sendoutEmail: "String"
    },
    PublicPlatform: {
        icon: "String",
        id: "Int",
        slug: "String",
        title: "String"
    },
    PublicPricedCart: {
        items: "PublicPricedCartItem",
        totalAmount: "Float",
        totalListPrice: "Float",
        unitCount: "Int"
    },
    PublicPricedCartItem: {
        listPrice: "Float",
        locationName: "String",
        onlineProfile: "PublicOnlineProfile",
        platform: "PublicPlatform",
        unitCount: "Int",
        unitPrice: "Float"
    },
    PublicRemovalEntry: {
        id: "Int",
        reviewId: "String",
        reviewText: "String",
        reviewerLink: "String",
        reviewerName: "String",
        status: "RemovalEntryStatus"
    },
    Query: {
        _locationsByIds: "Location",
        activityCallDuration: "AdminAgentCallDurationData",
        activityOverview: "AdminAgentActivityData",
        allCustomers: "AdminCustomerPaginatedList",
        allEmailTemplates: "AdminEmailTemplate",
        allKeywords: "Keyword",
        allLawyers: "AdminLawyer",
        allLeadAllocationRulesRegion: "AdminLeadAllocationRuleRegion",
        allOffers: "AdminOfferPaginatedList",
        allOnlineProfiles: "OnlineProfile",
        allOrders: "AdminOrderPaginatedList",
        allPayoutsForStaff: "AdminPayoutPaginatedList",
        allPermissions: "Permission",
        allPlatforms: "Platform",
        allPriceCategories: "AdminPricingCategory",
        allProducts: "AdminProduct",
        allReviews: "ReviewPaginatedList",
        allReviewsIds: "Review",
        allRoles: "AdminRole",
        allScans: "Scan",
        allSquads: "AdminSquad",
        allStaff: "AdminStaffPaginatedList",
        allTemplateVariables: "AdminTemplateVariable",
        allTimelineEntries: "AdminTimelinePaginatedList",
        basePrice: "Int",
        billedStats: "AdminBillingStats",
        categoryStats: "AdminCategoryStatsList",
        celloTokenGuest: "PublicCustomerReferrer",
        customer: "Customer",
        customerAgeStats: "AdminCustomerAgeStatsMetrics",
        customerBreakdownByPeriod: "AdminLineData",
        customerCrawlStatus: "ReviewsCustomerCrawlStatus",
        customerOverview: "AdminCustomerStatsOverview",
        customerStats: "AdminCustomerStatsList",
        customerSupplyStats: "AdminCustomerSupplyStats",
        customersTotalNegativeReviewsCount: "Customer",
        duplicateRemovalEntries: "String",
        entriesWithIds: "AdminRemovalEntry",
        exportOrders: "AdminAttachment",
        getDuplicateLocations: "AdminLocationCustomerId",
        googleForwardedBreakdownByPeriod: "AdminLineData",
        googleMyBusinessLocations: "GoogleMyBusinessLocation",
        googleRollAvgBreakdownByPeriod: "AdminLineData",
        lead: "AdminLead",
        leadCategories: "AdminLeadCategoryPaginatedList",
        leadLossStats: "AdminLeadLossMetricData",
        leadStatsAvgDaysWonLost: "AdminLeadsWonLostMetric",
        leadStatuses: "AdminLeadStatusAggregate",
        leaderboardMonthlySales: "AdminLineData",
        leaderboardMostCustomers: "AdminStatsAbstractStaffMetric",
        leaderboardSquads: "AdminSquadStats",
        leaderboardTopPerformers: "AdminStatsAbstractStaffMetric",
        leads: "AdminLeadPaginatedList",
        leadsIds: "AdminLeadId",
        loadEmailTemplate: "AdminDocumentTemplate",
        locationCategoriesStats: "AdminSalesStats",
        mailEvent: "AdminEventMail",
        mailEventAttachment: "String",
        managerStats: "AdminManagerStatsList",
        me: "AdminUser",
        myDelegatedTasks: "AdminTaskPaginatedList",
        myEmailTemplates: "AdminEmailTemplate",
        myLocations: "AdminLocationPaginatedList",
        myOrders: "PublicOrderPaginatedList",
        myReviewPlatforms: "Platform",
        myTasks: "AdminTaskPaginatedList",
        offer: "AdminOffer",
        offerById: "AdminOffer",
        offerCustomer: "AdminAttachment",
        offerLead: "AdminAttachment",
        offerOverview: "AdminOfferOverviewStats",
        offlineCampaign: "AdminLeadOfflineCampaign",
        offlineCampaignLeads: "AdminLeadPaginatedList",
        offlineCampaigns: "AdminLeadOfflineCampaign",
        onlineProfile: "OnlineProfile",
        openAIKununuPrompt: "String",
        order: "AdminOrder",
        outstandingTasks: "Int",
        ownSalesBreakdownByPeriod: "AdminLineData",
        payment: "AdminPayment",
        payoutRelatedTimelineEntries: "AdminTimelinePaginatedList",
        place: "AdminPlace",
        platformStats: "AdminOverallPlatformStats",
        platformsBreakdownByPeriod: "AdminLineData",
        pricedCart: "PublicPricedCart",
        productsOverview: "AdminStatsAllProductsOverviewMetric",
        publicOrder: "PublicOrder",
        publicReviews: "Review",
        referralCampaign: "AdminReferralCampaign",
        removalsStats: "AdminRemovalStats",
        reportCreatedInvoices: "AdminAttachment",
        reportCustomerReviews: "Boolean",
        reportFactoringInvoices: "AdminAttachment",
        reportInitiatedForwarded: "AdminAttachment",
        reportNotForwarded: "AdminAttachment",
        reportOrderOverview: "AdminAttachment",
        reviewCountProgress: "ReviewCountData",
        salesBreakdownByPeriod: "AdminLineData",
        salesByAgents: "AdminSalesByAgentsMetrics",
        salesCompareByPeriod: "AdminLineData",
        salesOverview: "AdminStatsSalesOverviewMetric",
        scanCache: "ScanCache",
        scoreOptimization: "ScoreOptimization",
        scoreProgress: "LineData",
        scoreProgress6Months: "LineData",
        scrapablePlatforms: "Platform",
        sourcesOverview: "AdminSalesStats",
        staff: "AdminStaff",
        statsOverview: "StatsOverview",
        weeklyReviewSummary: "LineData"
    },
    ReputationCheck: {
        companyAddress: "String",
        companyName: "String",
        currentScore: "Float",
        id: "String",
        mapsLink: "String",
        negativeReviewCount: "Int",
        negativeReviews: "Review",
        optimizedScore: "Float",
        removableReviewCount: "Int",
        totalReviewCount: "Int"
    },
    Review: {
        commentUrl: "String",
        createdAt: "DateTime",
        date: "DateTime",
        description: "String",
        id: "String",
        normalizedScore: "Float",
        onlineProfile: "OnlineProfile",
        onlineProfileId: "String",
        overallScore: "Float",
        platformSpecificId: "String",
        removabilityScore: "Float",
        removalEntryStatuses: "RemovalEntryStatus",
        responseFromOwnerDate: "DateTime",
        responseFromOwnerText: "String",
        reviewerName: "String",
        subReviews: "SubReview",
        title: "String",
        updatedAt: "DateTime",
        url: "String"
    },
    ReviewCountData: {
        datasets: "ReviewCountDataSet",
        labels: "String"
    },
    ReviewCountDataPoint: {
        negativeCreated: "Int",
        negativeLost: "Int",
        neutralCreated: "Int",
        neutralLost: "Int",
        positiveCreated: "Int",
        positiveLost: "Int"
    },
    ReviewCountDataSet: {
        data: "ReviewCountDataPoint",
        label: "String"
    },
    ReviewPaginatedList: {
        items: "Review",
        totalCount: "Float"
    },
    ReviewsCustomerCrawlStatus: {
        isCrawling: "Boolean",
        isInitial: "Boolean",
        progress: "Float"
    },
    Scan: {
        createdAt: "DateTime",
        id: "String",
        onlineProfileId: "String",
        reviewCount: "Int",
        status: "ScanStatus",
        updatedAt: "DateTime"
    },
    ScanCache: {
        id: "String",
        link: "String",
        platform: "Platform",
        platformSlug: "String",
        reviews: "ReviewPaginatedList",
        status: "ScanStatus"
    },
    ScanPlaceIdResult: {
        createdAt: "DateTime",
        id: "String",
        updatedAt: "DateTime"
    },
    ScoreDataPoint: {
        score: "Float"
    },
    ScoreOptimization: {
        currentReviewCount: "Int",
        currentScore: "Float",
        optimizedReviewCount: "Int",
        optimizedScore: "Float"
    },
    StatsOverview: {
        count: "Int",
        overallScore: "Float",
        scoreDistribution: "StatsOverviewDatapoint"
    },
    StatsOverviewDatapoint: {
        count: "Int",
        percent: "Float",
        stars: "Int"
    },
    SubReview: {
        createdAt: "DateTime",
        id: "String",
        score: "Float",
        updatedAt: "DateTime"
    },
    TimelineEntryEvent: {
        "...on AdminEventCall": "AdminEventCall",
        "...on AdminEventLog": "AdminEventLog",
        "...on AdminEventMail": "AdminEventMail",
        "...on AdminEventNote": "AdminEventNote",
        "...on AdminEventUpdate": "AdminEventUpdate",
        "...on AdminTask": "AdminTask"
    },
    TimelineEntryEventAssociation: {
        "...on AdminEventCall": "AdminEventCall",
        "...on AdminLead": "AdminLead",
        "...on AdminOffer": "AdminOffer",
        "...on AdminOrder": "AdminOrder",
        "...on AdminPayment": "AdminPayment",
        "...on Customer": "Customer"
    },
    Upload: `scalar.Upload`,
    UserOwnerUnion: {
        "...on AdminStaff": "AdminStaff",
        "...on Customer": "Customer"
    }
};
export const Ops = {
    mutation: "Mutation",
    query: "Query"
}; /* import-sort-ignore */
