import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
export const AuthenticatedContext = React.createContext({
    authenticated: undefined,
    setAuthenticated: () => null,
});
export const AuthenticatedProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState();
    return (_jsx(AuthenticatedContext.Provider, Object.assign({ value: { setAuthenticated, authenticated } }, { children: children })));
};
export function useAuthenticatedContext() {
    return useContext(AuthenticatedContext);
}
