/* eslint-disable */
import { AllTypesProps, Ops, ReturnTypes } from './const';
export const HOST = "http://localhost:4000";
export const HEADERS = {};
export const apiSubscription = (options) => (query) => {
    var _a, _b, _c;
    try {
        const queryString = options[0] + '?query=' + encodeURIComponent(query);
        const wsString = queryString.replace('http', 'ws');
        const host = (options.length > 1 && ((_b = (_a = options[1]) === null || _a === void 0 ? void 0 : _a.websocket) === null || _b === void 0 ? void 0 : _b[0])) || wsString;
        const webSocketOptions = ((_c = options[1]) === null || _c === void 0 ? void 0 : _c.websocket) || [host];
        const ws = new WebSocket(...webSocketOptions);
        return {
            ws,
            on: (e) => {
                ws.onmessage = (event) => {
                    if (event.data) {
                        const parsed = JSON.parse(event.data);
                        const data = parsed.data;
                        return e(data);
                    }
                };
            },
            off: (e) => {
                ws.onclose = e;
            },
            error: (e) => {
                ws.onerror = e;
            },
            open: (e) => {
                ws.onopen = e;
            },
        };
    }
    catch (_d) {
        throw new Error('No websockets implemented');
    }
};
const handleFetchResponse = (response) => {
    if (!response.ok) {
        return new Promise((_, reject) => {
            response
                .text()
                .then((text) => {
                try {
                    reject(JSON.parse(text));
                }
                catch (err) {
                    reject(text);
                }
            })
                .catch(reject);
        });
    }
    return response.json();
};
export const apiFetch = (options) => (query, variables = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
        return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
            .then(handleFetchResponse)
            .then((response) => {
            if (response.errors) {
                throw new GraphQLError(response);
            }
            return response.data;
        });
    }
    return fetch(`${options[0]}`, Object.assign({ body: JSON.stringify({ query, variables }), method: 'POST', headers: {
            'Content-Type': 'application/json',
        } }, fetchOptions))
        .then(handleFetchResponse)
        .then((response) => {
        if (response.errors) {
            throw new GraphQLError(response);
        }
        return response.data;
    });
};
export const InternalsBuildQuery = ({ ops, props, returns, options, scalars, }) => {
    const ibb = (k, o, p = '', root = true, vars = []) => {
        var _a;
        const keyForPath = purifyGraphQLKey(k);
        const newPath = [p, keyForPath].join(SEPARATOR);
        if (!o) {
            return '';
        }
        if (typeof o === 'boolean' || typeof o === 'number') {
            return k;
        }
        if (typeof o === 'string') {
            return `${k} ${o}`;
        }
        if (Array.isArray(o)) {
            const args = InternalArgsBuilt({
                props,
                returns,
                ops,
                scalars,
                vars,
            })(o[0], newPath);
            return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
        }
        if (k === '__alias') {
            return Object.entries(o)
                .map(([alias, objectUnderAlias]) => {
                if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
                    throw new Error('Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}');
                }
                const operationName = Object.keys(objectUnderAlias)[0];
                const operation = objectUnderAlias[operationName];
                return ibb(`${alias}:${operationName}`, operation, p, false, vars);
            })
                .join('\n');
        }
        const hasOperationName = root && (options === null || options === void 0 ? void 0 : options.operationName) ? ' ' + options.operationName : '';
        const keyForDirectives = (_a = o.__directives) !== null && _a !== void 0 ? _a : '';
        const query = `{${Object.entries(o)
            .filter(([k]) => k !== '__directives')
            .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
            .join('\n')}}`;
        if (!root) {
            return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
        }
        const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
        return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
    };
    return ibb;
};
export const Thunder = (fn) => (operation, graphqlOptions) => (o, ops) => fn(Zeus(operation, o, {
    operationOptions: ops,
    scalars: graphqlOptions === null || graphqlOptions === void 0 ? void 0 : graphqlOptions.scalars,
}), ops === null || ops === void 0 ? void 0 : ops.variables).then((data) => {
    if (graphqlOptions === null || graphqlOptions === void 0 ? void 0 : graphqlOptions.scalars) {
        return decodeScalarsInResponse({
            response: data,
            initialOp: operation,
            initialZeusQuery: o,
            returns: ReturnTypes,
            scalars: graphqlOptions.scalars,
            ops: Ops,
        });
    }
    return data;
});
export const Chain = (...options) => Thunder(apiFetch(options));
export const SubscriptionThunder = (fn) => (operation, graphqlOptions) => (o, ops) => {
    const returnedFunction = fn(Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions === null || graphqlOptions === void 0 ? void 0 : graphqlOptions.scalars,
    }));
    if ((returnedFunction === null || returnedFunction === void 0 ? void 0 : returnedFunction.on) && (graphqlOptions === null || graphqlOptions === void 0 ? void 0 : graphqlOptions.scalars)) {
        const wrapped = returnedFunction.on;
        returnedFunction.on = (fnToCall) => wrapped((data) => {
            if (graphqlOptions === null || graphqlOptions === void 0 ? void 0 : graphqlOptions.scalars) {
                return fnToCall(decodeScalarsInResponse({
                    response: data,
                    initialOp: operation,
                    initialZeusQuery: o,
                    returns: ReturnTypes,
                    scalars: graphqlOptions.scalars,
                    ops: Ops,
                }));
            }
            return fnToCall(data);
        });
    }
    return returnedFunction;
};
export const Subscription = (...options) => SubscriptionThunder(apiSubscription(options));
export const Zeus = (operation, o, ops) => InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops === null || ops === void 0 ? void 0 : ops.operationOptions,
    scalars: ops === null || ops === void 0 ? void 0 : ops.scalars,
})(operation, o);
export const ZeusSelect = () => ((t) => t);
export const Selector = (key) => key && ZeusSelect();
export const TypeFromSelector = (key) => key && ZeusSelect();
export const Gql = Chain(HOST, {
    headers: Object.assign({ 'Content-Type': 'application/json' }, HEADERS),
});
export const ZeusScalars = ZeusSelect();
export const decodeScalarsInResponse = ({ response, scalars, returns, ops, initialZeusQuery, initialOp, }) => {
    if (!scalars) {
        return response;
    }
    const builder = PrepareScalarPaths({
        ops,
        returns,
    });
    const scalarPaths = builder(initialOp, ops[initialOp], initialZeusQuery);
    if (scalarPaths) {
        const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp, response, [ops[initialOp]]);
        return r;
    }
    return response;
};
export const traverseResponse = ({ resolvers, scalarPaths, }) => {
    const ibb = (k, o, p = []) => {
        var _a;
        if (Array.isArray(o)) {
            return o.map((eachO) => ibb(k, eachO, p));
        }
        if (o == null) {
            return o;
        }
        const scalarPathString = p.join(SEPARATOR);
        const currentScalarString = scalarPaths[scalarPathString];
        if (currentScalarString) {
            const currentDecoder = (_a = resolvers[currentScalarString.split('.')[1]]) === null || _a === void 0 ? void 0 : _a.decode;
            if (currentDecoder) {
                return currentDecoder(o);
            }
        }
        if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
            return o;
        }
        return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
    };
    return ibb;
};
export const SEPARATOR = '|';
export class GraphQLError extends Error {
    constructor(response) {
        super('');
        this.response = response;
        console.error(response);
    }
    toString() {
        return 'GraphQL Response Error';
    }
}
const ExtractScalar = (mappedParts, returns) => {
    if (mappedParts.length === 0) {
        return;
    }
    const oKey = mappedParts[0];
    const returnP1 = returns[oKey];
    if (typeof returnP1 === 'object') {
        const returnP2 = returnP1[mappedParts[1]];
        if (returnP2) {
            return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
        }
        return undefined;
    }
    return returnP1;
};
export const PrepareScalarPaths = ({ ops, returns }) => {
    const ibb = (k, originalKey, o, p = [], pOriginals = [], root = true) => {
        if (!o) {
            return;
        }
        if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
            const extractionArray = [...pOriginals, originalKey];
            const isScalar = ExtractScalar(extractionArray, returns);
            if (isScalar === null || isScalar === void 0 ? void 0 : isScalar.startsWith('scalar')) {
                const partOfTree = {
                    [[...p, k].join(SEPARATOR)]: isScalar,
                };
                return partOfTree;
            }
            return {};
        }
        if (Array.isArray(o)) {
            return ibb(k, k, o[1], p, pOriginals, false);
        }
        if (k === '__alias') {
            return Object.entries(o)
                .map(([alias, objectUnderAlias]) => {
                if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
                    throw new Error('Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}');
                }
                const operationName = Object.keys(objectUnderAlias)[0];
                const operation = objectUnderAlias[operationName];
                return ibb(alias, operationName, operation, p, pOriginals, false);
            })
                .reduce((a, b) => (Object.assign(Object.assign({}, a), b)));
        }
        const keyName = root ? ops[k] : k;
        return Object.entries(o)
            .filter(([k]) => k !== '__directives')
            .map(([k, v]) => {
            // Inline fragments shouldn't be added to the path as they aren't a field
            const isInlineFragment = originalKey.match(/^...\s*on/) != null;
            return ibb(k, k, v, isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)], isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)], false);
        })
            .reduce((a, b) => (Object.assign(Object.assign({}, a), b)));
    };
    return ibb;
};
export const purifyGraphQLKey = (k) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');
const mapPart = (p) => {
    const [isArg, isField] = p.split('<>');
    if (isField) {
        return {
            v: isField,
            __type: 'field',
        };
    }
    return {
        v: isArg,
        __type: 'arg',
    };
};
export const ResolveFromPath = (props, returns, ops) => {
    const ResolvePropsType = (mappedParts) => {
        const oKey = ops[mappedParts[0].v];
        const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
        if (propsP1 === 'enum' && mappedParts.length === 1) {
            return 'enum';
        }
        if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
            return propsP1;
        }
        if (typeof propsP1 === 'object') {
            if (mappedParts.length < 2) {
                return 'not';
            }
            const propsP2 = propsP1[mappedParts[1].v];
            if (typeof propsP2 === 'string') {
                return rpp(`${propsP2}${SEPARATOR}${mappedParts
                    .slice(2)
                    .map((mp) => mp.v)
                    .join(SEPARATOR)}`);
            }
            if (typeof propsP2 === 'object') {
                if (mappedParts.length < 3) {
                    return 'not';
                }
                const propsP3 = propsP2[mappedParts[2].v];
                if (propsP3 && mappedParts[2].__type === 'arg') {
                    return rpp(`${propsP3}${SEPARATOR}${mappedParts
                        .slice(3)
                        .map((mp) => mp.v)
                        .join(SEPARATOR)}`);
                }
            }
        }
    };
    const ResolveReturnType = (mappedParts) => {
        if (mappedParts.length === 0) {
            return 'not';
        }
        const oKey = ops[mappedParts[0].v];
        const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
        if (typeof returnP1 === 'object') {
            if (mappedParts.length < 2)
                return 'not';
            const returnP2 = returnP1[mappedParts[1].v];
            if (returnP2) {
                return rpp(`${returnP2}${SEPARATOR}${mappedParts
                    .slice(2)
                    .map((mp) => mp.v)
                    .join(SEPARATOR)}`);
            }
        }
    };
    const rpp = (path) => {
        const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
        const mappedParts = parts.map(mapPart);
        const propsP1 = ResolvePropsType(mappedParts);
        if (propsP1) {
            return propsP1;
        }
        const returnP1 = ResolveReturnType(mappedParts);
        if (returnP1) {
            return returnP1;
        }
        return 'not';
    };
    return rpp;
};
export const InternalArgsBuilt = ({ props, ops, returns, scalars, vars, }) => {
    const arb = (a, p = '', root = true) => {
        var _a, _b;
        if (typeof a === 'string') {
            if (a.startsWith(START_VAR_NAME)) {
                const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
                const v = vars.find((v) => v.name === varName);
                if (!v) {
                    vars.push({
                        name: varName,
                        graphQLType,
                    });
                }
                else {
                    if (v.graphQLType !== graphQLType) {
                        throw new Error(`Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`);
                    }
                }
                return varName;
            }
        }
        const checkType = ResolveFromPath(props, returns, ops)(p);
        if (checkType.startsWith('scalar.')) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, ...splittedScalar] = checkType.split('.');
            const scalarKey = splittedScalar.join('.');
            return ((_b = (_a = scalars === null || scalars === void 0 ? void 0 : scalars[scalarKey]) === null || _a === void 0 ? void 0 : _a.encode) === null || _b === void 0 ? void 0 : _b.call(_a, a)) || JSON.stringify(a);
        }
        if (Array.isArray(a)) {
            return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
        }
        if (typeof a === 'string') {
            if (checkType === 'enum') {
                return a;
            }
            return `${JSON.stringify(a)}`;
        }
        if (typeof a === 'object') {
            if (a === null) {
                return `null`;
            }
            const returnedObjectString = Object.entries(a)
                .filter(([, v]) => typeof v !== 'undefined')
                .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
                .join(',\n');
            if (!root) {
                return `{${returnedObjectString}}`;
            }
            return returnedObjectString;
        }
        return `${a}`;
    };
    return arb;
};
export const resolverFor = (type, field, fn) => fn;
export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;
export const $ = (name, graphqlType) => {
    return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType);
};
export var AdminLeadStatus;
(function (AdminLeadStatus) {
    AdminLeadStatus["appointment"] = "appointment";
    AdminLeadStatus["inContact"] = "inContact";
    AdminLeadStatus["lost"] = "lost";
    AdminLeadStatus["new"] = "new";
    AdminLeadStatus["onHold"] = "onHold";
    AdminLeadStatus["proposal"] = "proposal";
    AdminLeadStatus["rescue"] = "rescue";
    AdminLeadStatus["won"] = "won";
})(AdminLeadStatus || (AdminLeadStatus = {}));
export var CallDirectionOptions;
(function (CallDirectionOptions) {
    CallDirectionOptions["all"] = "all";
    CallDirectionOptions["inbound"] = "inbound";
    CallDirectionOptions["outbound"] = "outbound";
})(CallDirectionOptions || (CallDirectionOptions = {}));
export var CallOutcome;
(function (CallOutcome) {
    CallOutcome["busy"] = "busy";
    CallOutcome["connected"] = "connected";
    CallOutcome["couldNotReachDecider"] = "couldNotReachDecider";
    CallOutcome["leftVoicemail"] = "leftVoicemail";
    CallOutcome["noAnswer"] = "noAnswer";
    CallOutcome["wrongNumber"] = "wrongNumber";
})(CallOutcome || (CallOutcome = {}));
export var EmailOptions;
(function (EmailOptions) {
    EmailOptions["all"] = "all";
    EmailOptions["received"] = "received";
    EmailOptions["sent"] = "sent";
})(EmailOptions || (EmailOptions = {}));
export var EventType;
(function (EventType) {
    EventType["call"] = "call";
    EventType["logCall"] = "logCall";
    EventType["logMail"] = "logMail";
    EventType["mail"] = "mail";
    EventType["note"] = "note";
    EventType["task"] = "task";
    EventType["update"] = "update";
})(EventType || (EventType = {}));
export var Gender;
(function (Gender) {
    Gender["female"] = "female";
    Gender["male"] = "male";
    Gender["other"] = "other";
})(Gender || (Gender = {}));
export var KPI;
(function (KPI) {
    KPI["avgRemovalPrice"] = "avgRemovalPrice";
    KPI["paid"] = "paid";
    KPI["sales"] = "sales";
})(KPI || (KPI = {}));
export var OfferStatus;
(function (OfferStatus) {
    OfferStatus["accepted"] = "accepted";
    OfferStatus["cancelled"] = "cancelled";
    OfferStatus["expired"] = "expired";
    OfferStatus["pending"] = "pending";
})(OfferStatus || (OfferStatus = {}));
export var OrderSource;
(function (OrderSource) {
    OrderSource["coldCall"] = "coldCall";
    OrderSource["coldEmail"] = "coldEmail";
    OrderSource["customerPortal"] = "customerPortal";
    OrderSource["direct"] = "direct";
    OrderSource["newsletter"] = "newsletter";
    OrderSource["onlineAds"] = "onlineAds";
    OrderSource["partner"] = "partner";
    OrderSource["recommendation"] = "recommendation";
    OrderSource["secondSale"] = "secondSale";
    OrderSource["support"] = "support";
    OrderSource["websiteChat"] = "websiteChat";
})(OrderSource || (OrderSource = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["cancelled"] = "cancelled";
    OrderStatus["completed"] = "completed";
    OrderStatus["inProgress"] = "inProgress";
})(OrderStatus || (OrderStatus = {}));
export var OrderTypeStatus;
(function (OrderTypeStatus) {
    OrderTypeStatus["cancelled"] = "cancelled";
    OrderTypeStatus["completed"] = "completed";
    OrderTypeStatus["forwarded"] = "forwarded";
    OrderTypeStatus["initiated"] = "initiated";
    OrderTypeStatus["onHold"] = "onHold";
    OrderTypeStatus["open"] = "open";
    OrderTypeStatus["partiallyCompleted"] = "partiallyCompleted";
    OrderTypeStatus["partiallyForwarded"] = "partiallyForwarded";
})(OrderTypeStatus || (OrderTypeStatus = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["crefoFactoring"] = "crefoFactoring";
    PaymentMethod["direct"] = "direct";
    PaymentMethod["factoring"] = "factoring";
    PaymentMethod["lsv"] = "lsv";
})(PaymentMethod || (PaymentMethod = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["c1"] = "c1";
    PaymentStatus["c2"] = "c2";
    PaymentStatus["c3"] = "c3";
    PaymentStatus["cancelled"] = "cancelled";
    PaymentStatus["inkasso"] = "inkasso";
    PaymentStatus["invoiceSent"] = "invoiceSent";
    PaymentStatus["m1"] = "m1";
    PaymentStatus["m2"] = "m2";
    PaymentStatus["new"] = "new";
    PaymentStatus["paid"] = "paid";
    PaymentStatus["refunded"] = "refunded";
    PaymentStatus["voided"] = "voided";
})(PaymentStatus || (PaymentStatus = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["credit"] = "credit";
    PaymentType["final"] = "final";
    PaymentType["installment"] = "installment";
    PaymentType["partial"] = "partial";
    PaymentType["pre"] = "pre";
    PaymentType["single"] = "single";
    PaymentType["storno"] = "storno";
})(PaymentType || (PaymentType = {}));
export var Permission;
(function (Permission) {
    Permission["allocateLead"] = "allocateLead";
    Permission["bulkWriteLeads"] = "bulkWriteLeads";
    Permission["createInvoice"] = "createInvoice";
    Permission["destroyEntry"] = "destroyEntry";
    Permission["importLeads"] = "importLeads";
    Permission["offlineCampaigns"] = "offlineCampaigns";
    Permission["readAllTasks"] = "readAllTasks";
    Permission["readCustomers"] = "readCustomers";
    Permission["readLawyers"] = "readLawyers";
    Permission["readLeads"] = "readLeads";
    Permission["readOffers"] = "readOffers";
    Permission["readOrders"] = "readOrders";
    Permission["readOwnCustomers"] = "readOwnCustomers";
    Permission["readOwnLeads"] = "readOwnLeads";
    Permission["readOwnOffers"] = "readOwnOffers";
    Permission["readOwnOrders"] = "readOwnOrders";
    Permission["readOwnSalesStats"] = "readOwnSalesStats";
    Permission["readPartners"] = "readPartners";
    Permission["readPeerOffers"] = "readPeerOffers";
    Permission["readProducts"] = "readProducts";
    Permission["readRoles"] = "readRoles";
    Permission["readSalesStats"] = "readSalesStats";
    Permission["readStaff"] = "readStaff";
    Permission["readTemplates"] = "readTemplates";
    Permission["scanPartners"] = "scanPartners";
    Permission["searchLeads"] = "searchLeads";
    Permission["sendEmail"] = "sendEmail";
    Permission["writeCustomerManager"] = "writeCustomerManager";
    Permission["writeCustomerPricing"] = "writeCustomerPricing";
    Permission["writeCustomers"] = "writeCustomers";
    Permission["writeLawyers"] = "writeLawyers";
    Permission["writeLeadIsKeyAccount"] = "writeLeadIsKeyAccount";
    Permission["writeLeadManager"] = "writeLeadManager";
    Permission["writeLeads"] = "writeLeads";
    Permission["writeOffers"] = "writeOffers";
    Permission["writeOrders"] = "writeOrders";
    Permission["writePayouts"] = "writePayouts";
    Permission["writeProducts"] = "writeProducts";
    Permission["writeRoles"] = "writeRoles";
    Permission["writeStaff"] = "writeStaff";
    Permission["writeTemplates"] = "writeTemplates";
})(Permission || (Permission = {}));
export var ProductType;
(function (ProductType) {
    ProductType["listingRemoval"] = "listingRemoval";
    ProductType["localBoost"] = "localBoost";
    ProductType["removal"] = "removal";
})(ProductType || (ProductType = {}));
export var RemovalEntryStatus;
(function (RemovalEntryStatus) {
    RemovalEntryStatus["answer"] = "answer";
    RemovalEntryStatus["cancelled"] = "cancelled";
    RemovalEntryStatus["declined"] = "declined";
    RemovalEntryStatus["forwarded"] = "forwarded";
    RemovalEntryStatus["initiated"] = "initiated";
    RemovalEntryStatus["onHold"] = "onHold";
    RemovalEntryStatus["open"] = "open";
    RemovalEntryStatus["removed"] = "removed";
})(RemovalEntryStatus || (RemovalEntryStatus = {}));
export var ReviewRating;
(function (ReviewRating) {
    ReviewRating["bad"] = "bad";
    ReviewRating["good"] = "good";
    ReviewRating["neutral"] = "neutral";
})(ReviewRating || (ReviewRating = {}));
export var SalesStatsPeriodOption;
(function (SalesStatsPeriodOption) {
    SalesStatsPeriodOption["day"] = "day";
    SalesStatsPeriodOption["month"] = "month";
    SalesStatsPeriodOption["week"] = "week";
})(SalesStatsPeriodOption || (SalesStatsPeriodOption = {}));
export var ScanStatus;
(function (ScanStatus) {
    ScanStatus["error"] = "error";
    ScanStatus["inProgress"] = "inProgress";
    ScanStatus["pending"] = "pending";
    ScanStatus["success"] = "success";
})(ScanStatus || (ScanStatus = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder || (SortOrder = {}));
export var StringFilterMode;
(function (StringFilterMode) {
    StringFilterMode["default"] = "default";
    StringFilterMode["insensitive"] = "insensitive";
})(StringFilterMode || (StringFilterMode = {}));
export var TemplateVariableKey;
(function (TemplateVariableKey) {
    TemplateVariableKey["companyAddress"] = "companyAddress";
    TemplateVariableKey["companyCEO"] = "companyCEO";
    TemplateVariableKey["companyCity"] = "companyCity";
    TemplateVariableKey["companyCountry"] = "companyCountry";
    TemplateVariableKey["companyEmail"] = "companyEmail";
    TemplateVariableKey["companyName"] = "companyName";
    TemplateVariableKey["companyPhone"] = "companyPhone";
    TemplateVariableKey["companyRegistrationCourt"] = "companyRegistrationCourt";
    TemplateVariableKey["companyRegistrationNumber"] = "companyRegistrationNumber";
    TemplateVariableKey["companyVAT"] = "companyVAT";
    TemplateVariableKey["companyWebsite"] = "companyWebsite";
    TemplateVariableKey["companyZipCode"] = "companyZipCode";
    TemplateVariableKey["paymentAccountHolder"] = "paymentAccountHolder";
    TemplateVariableKey["paymentBIC"] = "paymentBIC";
    TemplateVariableKey["paymentBankName"] = "paymentBankName";
    TemplateVariableKey["paymentIBAN"] = "paymentIBAN";
})(TemplateVariableKey || (TemplateVariableKey = {}));
