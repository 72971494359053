import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
/**
 * Saves the old theme for future use
 * @param {string} theme - Name of curent theme
 * @return {string} previousTheme
 */
function usePrevious(theme) {
    const ref = useRef();
    useEffect(() => {
        ref.current = theme;
    });
    return ref.current;
}
// create context
export const ThemeContext = React.createContext({});
// create context provider
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('light');
    // update root element class on theme change
    const oldTheme = usePrevious(theme);
    useLayoutEffect(() => {
        document.documentElement.classList.remove(`theme-${oldTheme}`);
        document.documentElement.classList.add(`theme-${theme}`);
    }, [theme, oldTheme]);
    const toggleTheme = useCallback(() => {
        if (theme === 'light') {
            setTheme('dark');
        }
        else {
            return setTheme('light');
        }
    }, [setTheme, theme]);
    const value = useMemo(() => ({
        theme,
        toggleTheme,
    }), [theme, toggleTheme]);
    return (_jsx(ThemeContext.Provider, Object.assign({ value: value }, { children: children })));
};
export function useTheme() {
    return useContext(ThemeContext);
}
