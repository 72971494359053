import { ApolloClient, InMemoryCache } from '@apollo/client';
export const zenstarClient = new ApolloClient({
    uri: process.env.REACT_APP_GATEWAY_URL,
    cache: new InMemoryCache(),
    credentials: 'include',
    defaultOptions: {
        query: {
            notifyOnNetworkStatusChange: true,
        },
        watchQuery: {
            notifyOnNetworkStatusChange: true,
        },
    },
});
export const clearCacheOnClients = () => {
    return zenstarClient.clearStore();
};
